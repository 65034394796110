import axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK + "/client";

export const freezCard = async () => {

    const res = await axios.put(`${API_URL}/creditcard`, {}, {
        'headers': {
            'Authorization': `Stickball ${sessionStorage.getItem('token')}`,
            'x-api-key': process.env.REACT_APP_X_API_KEY
        }
    });
    return res.data;
}

export const getCard = async () => {
    const res = await axios.get(`${API_URL}/creditcard`, {
        'headers': {
            'Authorization': `Stickball ${sessionStorage.getItem('token')}`,
            'x-api-key': process.env.REACT_APP_X_API_KEY
        }
    })
    return (res.data)
}

export const checkout = async (data) => {
    const res = await axios.post(`${API_URL.replace("/client","")}/zilo/checkout`, data, {
        'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
    })
    return res;
}