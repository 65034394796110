
import React, { Component, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import PropertyModal from './modal'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
export default function Maps(props) {
  const [open, setOpen] = useState(false);
  const [modalProperty, setModalProperty] = useState({})

  const setProperty = async (property) => {
     setModalProperty(property)
  }
  const [center,setCenter] = useState({})
  useEffect(()=>{
    if(props.properties.length !== 0){
      setCenter({ lat: props.properties[0].geo.lat, lng: props.properties[0].geo.lng })
    }
    else{
      setCenter({ lat: 33.672480, lng: 72.988498 })
    }
  },[])
  const zoom = 9
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const buttonStyle = {
    color: 'white',
    background: '#fb785c',
    padding: '10px 10px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: 'none',
  }
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '70vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBCB4y0SztfLo236n4JECwTvIQd-VecF2Y" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {props.properties.map((item, i) =>

          <button style={buttonStyle}
            lat={item.geo.lat}
            lng={item.geo.lng}

            onClick={(e) => {
              e.preventDefault();
              setProperty(item)
              setOpen(true)
            }}>
          </button>)}


      </GoogleMapReact>
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PropertyModal {...modalProperty} />
        </Box>
      </Modal>
    </div>
  )
}
