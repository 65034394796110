import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Sell_sec6 = () => {

  return (
  <div className="continer-fluid" style={{background: '#f6f6fa'}}>
      <div className="container">
      <div className="row sell_1row justify-content-center py-3">
        <h4 className="py-4">Frequently asked questions</h4>
      <Accordion className="accordian_sec6">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
           <h6>When selling my house, where should I begin?</h6>
        </AccordionSummary>
        <AccordionDetails>
          <p>
          Start your home selling process by <a href="#"> gathering information </a> and considering your options. After choosing a selling path that works best for you, take the first step; contact an iBuyer, a real estate agent or begin home prep.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion  className="accordian_sec6">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <h6>How long does it take to sell a house?</h6>
        </AccordionSummary>
        <AccordionDetails>
          <p>
          On average, homes in the U.S. spend about one month on the market before going under contract, and another month or more to close. In 2020, the
        <a href="#"> average time to sell a home </a>was 55-70 days from list to close.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordian_sec6">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
  <h6>What home seller mistakes should I avoid?</h6>
        </AccordionSummary>
        <AccordionDetails>
          <p>
          Common <a href="#"> mistakes when selling a house </a> include overpricing, poor sale timing,
           incomplete home repairs, unprofessional marketing photography and forgetting to factor in all
            of the closing costs as a seller. Learn more about your likely <a href="#">home sale net proceeds.</a>

          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordian_sec6">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
            <h6>**Markets with a reduced listing fee</h6>
        </AccordionSummary>
        <AccordionDetails>
          <p>
           <ul>
            <li>Austin</li>
            <li>Charlotte</li>
            <li>LA County</li>
            <li>Las Vegas</li>
            <li>Miami</li>
            <li>Orange County</li>
            <li>Orlando</li>
            <li>Phoenix</li>
            <li>Portland</li>
            <li>Raleigh</li>
            <li>Riverside</li>
            <li>Sacramento</li>
            <li>San Antonio</li>
            <li>San Diego</li>
            <li>Tampa</li>
            <li>Tucson</li>
           </ul>
          </p>
        </AccordionDetails>
      </Accordion>
      </div>
    </div>
  </div>
  );
};

export default Sell_sec6;
