import React from "react";
import PageHeader from "./global-components/page-header";
import Navbar from "./global-components/navbar";
import Sell_sec1 from "./section-components/Sell_sec1";
import Sell_sec2 from "./section-components/Sell_sec2";
import Sell_sec3 from "./section-components/Sell_sec3";
import Sell_sec4 from "./section-components/Sell_sec4";
import Sell_sec5 from "./section-components/Sell_sec5";
import Sell_sec6 from "./section-components/Sell_sec6";
import Footer_v1 from "./global-components/footer";

const Sell = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Sell your home with confidence" />

      <Sell_sec1 />
      <Sell_sec2 />
      <Sell_sec3 />
      <Sell_sec4 />
      <Sell_sec5 />
      <Sell_sec6 />
      <Footer_v1 />
    </div>
  );
};

export default Sell;
