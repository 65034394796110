import React from 'react';
import Navbar from './global-components/navbar';
// import PageHeader from './global-components/page-header';
import SearchListSection from './section-components/search-list';

const SearchList = () => {
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Properties" subheader="Apartment rent" /> */}
        <SearchListSection />
    </div>
}

export default SearchList

