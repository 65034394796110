import React, { Component, useEffect, useState } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

import { useQuery, useQueryClient } from 'react-query';
import { fetchProperties, getSingleProperty } from '../../api/api';
import { useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loading from '../common/loading';

export default function PropertyDetails() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { isLoding, isSuccess, data: singleProperty } = useQuery(['properties', id], () => getSingleProperty(id))
  let publicUrl = process.env.PUBLIC_URL + '/'
  let imagealt = 'image'
  const [location, setLocation] = useState('')

  useEffect(() => {
    // Map Source link 
    if (isSuccess) {
      var loc = "https://maps.google.com/maps?q=" + singleProperty.geo.lat + "," + singleProperty.geo.lng + "&hl=es;z=14&amp;&output=embed";
      setLocation(loc)
    }
  })
  if(!isSuccess){
    return <Loading/>
  }
  else
  return (
    <>
      {isSuccess ? <>
        <div className="property-details-area" >
          <div className="bg-gray pd-top-100 pd-bottom-90">
            <div className="container">
              <div className="row ">
                <div className="col-xl-9 col-lg-8">
                  <div className="property-details-slider">
                    <Carousel 
                      width={"95%"}
                      showStatus={false}
                      swipeable={true}
                    >
                      {singleProperty.photos.map((image) => (
                        <div>
                          <img className='carousal-image' src={image} alt={imagealt} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className="property-details-slider-info">
                    <h4>{singleProperty.association.name}</h4>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                  <div className="widget widget-owner-info mt-lg-0 mt-5">
                    <div className="owner-info text-center">
                      {/* <div className="thumb">
                        <img src={publicUrl + "/assets/img/news/21.png"} alt={imagealt} />
                      </div> */}
                      <div className="details">
                        <div className="property-details-slider-info">
                        <h5>Price:<span> ${(singleProperty.listPrice.toLocaleString())}</span></h5>
                        
                        </div>
                        <h6>{singleProperty.agent.firstName} {singleProperty.agent.lastName}</h6>
                        <span className="designation">Agent</span>
                        {/* <p className="reviews"><i className="fa fa-star" /><span>4.8</span> 70 Review</p> */}
                      </div>
                    </div>
                    <div className="contact">
                      <h6>Contact Us</h6>
                      <div className="rld-single-input">
                        <input type="text" placeholder="Full Name" />
                      </div>
                      <div className="rld-single-input">
                        <input type="text" placeholder="Email" />
                      </div>
                      <div className="rld-single-input">
                        <input type="text" placeholder="Messages" />
                      </div>
                      <button className='btn btn-yellow'>Send Messages</button>
                    </div>
                    <div className="contact-info">
                      <h6 className="mb-3">Contact Info</h6>
                      <div className="media">
                        <div className="media-left">
                          <img src={publicUrl + "/assets/img/icons/1.png"} alt={imagealt} />
                        </div>
                        <div className="media-body">
                          <p>Address</p>
                          <span>{singleProperty.address.full}</span>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-left">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="media-body">
                          <p>Phone</p>
                          <span>{singleProperty.agent.contact.office}</span>
                        </div>
                      </div>
                      <div className="media mb-0">
                        <div className="media-left">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="media-body">
                          <p>Email</p>
                          <span>{singleProperty.agent.contact.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pd-top-90">
              <div className="col-lg-9">
                <div className="property-info mb-5">
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <div className="single-property-info">
                        <h5>Bedrooms</h5>
                        <p><i className="fa fa-bed" />0{singleProperty.property.bedrooms}</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-property-info">
                        <h5>Bathrooms</h5>
                        <p><i className="fa fa-bath" />0{singleProperty.property.bathsFull}</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-property-info">
                        <h5>Area</h5>
                        <p><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} />{singleProperty.property.area} sq. ft.</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-property-info">
                        <h5>Parking</h5>
                        <p><i className="fa fa-car" />{singleProperty.property.parking.spaces}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="property-news-single-card style-two border-bottom-yellow">
                  <h4>Remarks</h4>
                  <p>{singleProperty.privateRemarks}</p>
                  {/* <a href="#">Read More</a> */}
                </div>
                <div className="property-news-single-card style-two border-bottom-yellow">
                  <h4>Location</h4>
                  <iframe src={location} style={{ border: 0 }} allowFullScreen />
                </div>
                <div className="property-news-single-card border-bottom-yellow">
                  <h4>Amenities</h4>
                  <div className="row">
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        <li><i className="fa fa-check" /> Attic</li>
                        <li><i className="fa fa-check" /> Poll</li>
                        <li><i className="fa fa-check" /> Concierge</li>
                        <li><i className="fa fa-check" /> Basketball Cout</li>
                        <li><i className="fa fa-check" /> Sprinklers</li>
                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        <li><i className="fa fa-check" /> Recreation</li>
                        <li><i className="fa fa-check" /> Front Yard</li>
                        <li><i className="fa fa-check" /> Wine Cellar</li>
                        <li><i className="fa fa-check" /> Basketball Cout</li>
                        <li><i className="fa fa-check" /> Fireplace</li>
                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <ul className="rld-list-style mb-3 mb-sm-0">
                        <li><i className="fa fa-check" /> Balcony</li>
                        <li><i className="fa fa-check" /> Pound</li>
                        <li><i className="fa fa-check" /> Deck</li>
                        <li><i className="fa fa-check" /> 24x7 Security</li>
                        <li><i className="fa fa-check" /> Indoor Game</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="property-news-single-card border-bottom-yellow">
                  <h4>Floor Plan</h4>
                  <div className="thumb">
                    <img src={publicUrl + "/assets/img/others/10.png"} alt={imagealt} />
                  </div>
                </div> */}
                <div className="property-news-single-card border-bottom-yellow pb-3">
                  <h4>Facts and Features</h4>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <i className="fa fa-bed" />
                        </div>
                        <div className="media-body">
                          <h6>Living Room</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <i className="fa fa-car" />
                        </div>
                        <div className="media-body">
                          <h6>Garage</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} />
                        </div>
                        <div className="media-body">
                          <h6>Dining Area</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} />
                        </div>
                        <div className="media-body">
                          <h6>Dining Area</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <i className="fa fa-bed" />
                        </div>
                        <div className="media-body">
                          <h6>Bedroom</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <i className="fa fa-bath" />
                        </div>
                        <div className="media-body">
                          <h6>Bathroom</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <img src={publicUrl + "/assets/img/icons/17.png"} alt={imagealt} />
                        </div>
                        <div className="media-body">
                          <h6>Gym Area</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <img src={publicUrl + "/assets/img/icons/17.png"} alt={imagealt} />
                        </div>
                        <div className="media-body">
                          <h6>Gym Area</h6>
                          <p>20 x 16 sq feet</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="property-news-single-card border-bottom-yellow mb-0">
                  <h4>3D Gallery</h4>
                  <div className="thumb">
                    <img src={publicUrl + "/assets/img/others/11.png"} alt={imagealt} />
                  </div>
                </div> */}  
              </div>
            </div>
          </div>
        </div >
       </>
        :
        <></>
      }
    </>
  )
}

