import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Sell_sec5 = () => {

  return (
    <div className='container'>
        <div className="sec5_head text-center">
           <h4>Get acquainted with the process</h4>
            <p>As you begin the steps to selling, learn what to expect with our <a href="#"> Sellers Guide.</a></p>
           </div>
        <div className="row sell_1row justify-content-center">
          <div className="col-12 p-0">
        <Tabs
      defaultActiveKey="Preparing"
      id="fill-tab-example"
      className="mb-3 tabs_sell"
      fill
    >
      <Tab eventKey="Preparing" title="Preparing to sell">
        <div className="row">
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//preparing-to-sell-1.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>How to Sell a House As-Is When It Needs Repairs?</Card.Title>
        <p>
        What does it mean to sell a house as-is? Consider your options when you don't want to complete a long list of repairs before selling. Explore the best home improvements for resale and how to market your fixer-upper to sell quickly.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
         <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//preparing-to-sell-2.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>For Sale by Owner (FSBO) vs. Real Estate Agent?</Card.Title>
        <p>
        Description] When selling your home, a big decision awaits: Will you list with a real estate agent or list on your own as a for sale by owner (FSBO). Explore the benefits and drawbacks to both. Read on to make the best decision for your sale.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//preparing-to-sell-3.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>How to Sell My House Fast</Card.Title>
        <p>
        Need to sell your house fast? Consider investors or iBuyers. Otherwise, quick home sales rely on precise pricing, professional cleaning, marketing and negotiating. Follow these nine steps to sell fast.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//preparing-to-sell-4.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Steps to Selling a House</Card.Title>
        <p>
        Selling a house is complex. Follow these 10 critical steps to selling a house FSBO or with an agent. This first-time home sellers guide details, how to price your home competitively, stage to sell, avoid common closing hurdles and more.
        </p>
      </Card.Body>
    </Card>
          </div>
        </div>
      </Tab>
      <Tab eventKey="Timing" title="Timing your sale">
      <div className="row">
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//timing-your-sale-1.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>How Soon Can I Sell My House After Purchase?</Card.Title>
        <p>
        Need to sell your home sooner than expected? Learn more about your break even timeframe, amortization , fees and possible consequences for selling soon after purchasing.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
         <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//timing-your-sale-2.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Selling a Property With Tenants</Card.Title>
        <p>
        It's time to sell your rental property. But how do you approach the topic with your tenants? Learn more about sale timing, strategies and tenant considerations.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//timing-your-sale-3.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Can You Sell Your House Before Paying Off the Mortgage?</Card.Title>
        <p>
        Selling your home before it's paid off is a common occurrence. Learn about who pays the mortgage when selling, pricing to repay your balance and options for selling underwater homes.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//timing-your-sale-4.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>What Happens When You Inherit a House?</Card.Title>
        <p>
        It’s possible to inherit a property at fair market value and only pay capital gains tax from the time of inheritance to sale. But, existing mortgages and other stakeholders can complicate the process. Learn about your options.
        </p>
      </Card.Body>
    </Card>
          </div>
        </div>
      </Tab>
      <Tab eventKey="Pricing" title="Pricing your home">
      <div className="row">
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//pricing-your-home-1.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>How to Price Your Home to Sell</Card.Title>
        <p>
        When selling, you definitely want to get top dollar. Follow these nine steps to price your home competitively for your market, sell quickly and earn maximum profit.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
         <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//pricing-your-home-2.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Best Home Improvements to Increase Value</Card.Title>
        <p>
        If you're thinking about selling your home sometime in the next few years, make sure any renovations you complete add value. Learn more about home improvements with the best ROI, and a few to avoid.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//pricing-your-home-3.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Tips for Negotiating With Real Estate Agents</Card.Title>
        <p>
        When interviewing listing agents, the majority of sellers don't negotiate. Learn how to successfully negotiate terms of your listing agreement with this guide.        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//pricing-your-home-4.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>How to Calculate Home Equity</Card.Title>
        <p>
        Learn everything you need to know about your home equity: how to calculate it, how it increases, how much equity you need to sell and more in this helpful guide.        </p>
      </Card.Body>
    </Card>
          </div>
        </div>
      </Tab>
      <Tab eventKey="Getting" title="Getting noticed" >
      <div className="row">
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//getting-noticed-1.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>How to Market Your Home for Sale</Card.Title>
        <p>
        Whether you're working with an agent or selling your home on your own, how you market the listing is crucial to get the highest sale price. Read on for tips to successfully market your home for sale.        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
         <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//getting-noticed-2.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Property Descriptions 101: How to Write Listing Descriptions That Sell</Card.Title>
        <p>
        A listing description that's creative, engaging, and showcases your home's best features is one of the best ways to make your home stand out when it's for sale. Learn how to write a great description before you sell.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//getting-noticed-3.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Exterior Home Improvements for Resale</Card.Title>
        <p>
        Make a lasting first impression with your home's curb appeal, and maybe even net a higher sale price with recommended improvements, big and small.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//getting-noticed-4.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Tips for Showing Your House</Card.Title>
        <p>
        Whether you have an agent helping you show and sell your house or you're going it alone, you'll want to be prepared for open houses and showings. Follow these helpful prep and maintenance tips.
        </p>
      </Card.Body>
    </Card>
          </div>
        </div>
      </Tab>
      <Tab eventKey="Offers" title="Offers, closing & moving" >
      <div className="row">
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//offers-closing-moving-1.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>How Much Are Closing Costs for Sellers?</Card.Title>
        <p>
        As the seller, expect to pay 8%-10% of the home’s sale price in closing costs. Learn about required seller closing costs, due dates and ways to reduce closing costs for sellers.
        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
         <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//offers-closing-moving-2.png" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>The Home-Selling Process for an All-Cash Deal</Card.Title>
        <p>
        After accepting an all-cash offer to sell your home, it's time to prepare for closing. Read on to know what to expect, the 5-step closing process and ways it differs from selling to a financed buyer.        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//offers-closing-moving-3.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>The Appraisal Came in Low - Now What?</Card.Title>
        <p>
        The appraisal is a significant hurdle in most home sales. Learn all about home appraisals and what to do if yours comes back lower than expected.        </p>
      </Card.Body>
    </Card>
          </div>
          <div className="col-md-3 col-12">
          <Card className='tab_card' >
      <Card.Img variant="top" src="/home//assets/img//offers-closing-moving-4.jpg" className='tab_img'/>
      <Card.Body className='card_body'>
        <Card.Title className='card_title'>Should I Sell to a Home Investor?</Card.Title>
        <p>
        When your home's on the market, selling to a traditional buyer isn't your only option. Learn all about selling to an investor, what to expect, and how to avoid scams.        </p>
      </Card.Body>
    </Card>
          </div>
        </div>
      </Tab>
      
    </Tabs>
    <div className="text-center">
    <Button variant="contained"
      className='tab_btn'
      endIcon={<ArrowForwardIcon />}
     >
  View all article
</Button>
    </div>
    </div>
        </div>

    </div>
  )
}

export default Sell_sec5