import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import LazzyLoading from "./LazzyLoading";

const PdfViewer = ({ pdfUrl, height }) => {
  const [docs, setDocs] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const iframeDisplayStyle = isLoaded ? "block" : "none";

  const defaultStyle = {
    height: height ? height : "650px",
    width: "80%",
    margin: "0 auto",
    border: "1px solid #dddddd",
    ...{ display: iframeDisplayStyle },
  };

  const getDocument = async () => {
    try {
      const response = await axios.get(pdfUrl, { responseType: "blob" });

      if (!response.status == 200) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      console.log("TEST SET", JSON.stringify(response.headers));
      const pdfText = response.data;

      const blob = new Blob([pdfText], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(blob);

      setDocs(fileURL);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDocument();
  }, [pdfUrl]);

  return (
    <div>
      {!isLoaded && <LazzyLoading />}
      <iframe
        src={docs}
        onLoad={() => setIsLoaded(true)}
        style={defaultStyle}
      ></iframe>
    </div>
  );
};

export default memo(PdfViewer);
