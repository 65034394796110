import axios from "axios";
const API_URL = process.env.REACT_APP_API_LINK
const Axios = axios.create({
    'headers': {
        'authorization': `Stickball ${sessionStorage.getItem('token')}`,
        'x-api-key': process.env.REACT_APP_X_API_KEY,
    }
})

var username = "simplyrets"
var password = "simplyrets"
export const getFilteredProperties = async (filters, page, limit) => {
    const res = await Axios.get(API_URL + `/zillow/properties?page=${page}&limit=${limit}&pricestart=${filters?.startPrice}&priceend=${filters.endPrice}&bedrooms=${filters?.beds}&bathrooms=${filters?.bathrooms}&daysOnZillow=${filters?.daysOnZillow}`)
    const data = res.data;
    return data;
}

export const fetchProperties = async (page, limit) => {
    const res = await Axios.get(API_URL + `/zillow/properties?page=${page}&limit=${limit}`)
    const data = res.data;
    return data;
}

export const getSingleProperty = async () => {
    const res = await Axios.get(API_URL + "/zillow/properties?page=1&limit=20")
    const data = res.data;
    return data;
}

export const getFeaturedProperties = async () => {
    const res = await Axios.get(API_URL + "/properties", { headers: { 'Authorization': 'Basic ' + btoa(`${username}:${password}`) } })
    const data = res.data;
    var featured = [];
    for (let i = 4, j = 0; i < 8; i++, j++) {
        featured[j] = data[i]

    }
    return featured;
}

export const getCityProperties = async (city) => {
    const res = await Axios.get(API_URL + "/properties", { headers: { 'Authorization': 'Basic ' + btoa(`${username}:${password}`) } })
    const data = res.data;
    var propertyByCity = data.filter(data => city === data.address.city);
    return propertyByCity;
}

export const getRecentProperties = async () => {
    const res = await Axios.get(API_URL + "/properties", { headers: { 'Authorization': 'Basic ' + btoa(`${username}:${password}`) } })
    const data = res.data;
    var recent = [];
    for (let i = 8, j = 0; i < 16; i++, j++) {
        recent[j] = data[i]

    }
    return recent;
}