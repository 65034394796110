import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SkillAssignments from './skill-assignments'
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, Button } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite'
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import logoIcon from '../assets/logo.svg'

const Navbar = () => {
  const [openModal, setOpenModal] = useState(false)
  const [show, setShow] = useState(true)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  useEffect(() => {
    setShow(false)
    setTimeout(() => setShow(true), 100)
  }, [type])
  let cart = useSelector(state => state.cart.products)
  let wishlist = useSelector(state => state.wishlist.products)

  function handleChange(newValue) {
    setOpenModal(newValue);
  }

  const [isMobile, setIsMobile] = useState(false);
  function handleOrientation() {
    setIsMobile(window.innerWidth < 992)
  }
  window.onresize = handleOrientation;
  useEffect(() => {
    setIsMobile(window.innerWidth < 992)
  }, [window.innerWidth, window.innerHeight, window.orientation]);
  if (!show) return <></>
  return (
    <div>
      <div className="navbar-area" style={{ zIndex: 1000 }}>
        <nav className="navbar navbar-area navbar-expand-lg bg-white">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="collapse navbar-collapse" id="realdeal_main_menu">
              <ul className="navbar-nav menu-open readeal-top" style={{ textAlign: 'start' }}>
                <li className="menu-item-has-children">
                  <Link to="/search-list?type=buy">Buy</Link>
                  <div className="sub-menu d-flex">
                    <ul className='inner_option'>
                      <h6>Homes for sale</h6>
                      <li>
                        <Link to='/search-list?type=buy'>Home for sale</Link>
                      </li>
                      <li>
                        <Link to='/'>Foreclosures</Link>
                      </li>
                      <li>
                        <Link to='/'>For sale by owner</Link>
                      </li>
                      <li>
                        <Link to='/'>Open houses</Link>
                      </li>

                    </ul>
                    <ul className='inner_option'>
                      <h6>Bundle buying & selling</h6>
                      <li>
                        <Link to='/'>Buy and sell with Zillow 360</Link>
                      </li>
                    </ul>
                    <ul className='inner_option'>
                      <h6>Resources</h6>
                      <li>
                        <Link to='/'>Buyers Guide</Link>
                      </li>
                      <li>
                        <Link to='/'>Foreclosure center</Link>
                      </li>
                      <li>
                        <Link to='/'>Real estate app</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/search-list?type=rent">Rent</Link>
                  <ul className="sub-menu d-flex rent_hover" style={{ left: '-136px' }}>
                    <ul className='inner_option'>
                      <h6>Tonkawa rentals</h6>
                      <li>
                        <Link to='/search-list?type=rent'>Rental Bulidings</Link>
                      </li>
                      <li>
                        <Link to='/'>Apartments for rent</Link>
                      </li>
                      <li>
                        <Link to='/'>Houses for rent</Link>
                      </li>
                      <li>
                        <Link to='/'>All rental listings</Link>
                      </li>
                      <li>
                        <Link to='/'>All rental buildings</Link>
                      </li>
                    </ul>
                    <ul className='inner_option'>
                      <h6>Renter Hub</h6>
                      <li>
                        <Link to='/'>Contacted rentals</Link>
                      </li>
                      <li>
                        <Link to='/'>Your rental</Link>
                      </li>
                      <li>
                        <Link to='/'>Messages</Link>
                      </li>
                    </ul>
                    <ul className='inner_option'>
                      <h6>Resources</h6>
                      <li>
                        <Link to='/'>Affordability calculator</Link>
                      </li>
                      <li>
                        <Link to='/'>Renters guide</Link>
                      </li>
                    </ul>
                  </ul>
                </li>
                <li><Link to="/sell">Sell</Link></li>
                {/* <li><Link to="/About">Sell</Link></li> */}

                <li><Link to="/">Home Loans</Link></li>
                <li><Link to="/">Agent Finder</Link></li>
                {
                  isMobile &&
                  <>
                    <li>
                      <Link onClick={(e) => !cart?.length && e.preventDefault()} to="/cart" >
                        <Tooltip title={cart?.length ? "Cart" : "Cart is empty"}>
                          <IconButton aria-label="cart">
                            <Badge badgeContent={cart?.length} color="primary">
                              <ShoppingCartIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </li>

                    <li>
                      <Link onClick={(e) => !wishlist?.length && e.preventDefault()} to="/favorite-properties" >
                        <Tooltip title={wishlist?.length ? "Favorite Properties" : "You have no favorite properties"}>
                          <IconButton aria-label="cart">
                            <Badge badgeContent={wishlist?.length} color="primary">
                              <FavoriteIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </li>

                    <li><Button variant="contained"
                      onClick={(e) => {
                        setOpenModal(true)
                      }}>Skill Assignment</Button></li>
                  </>
                }
              </ul>
            </div>
            <div className="logo readeal-top">
              <Link to="/"><img src={logoIcon} alt="logo" /></Link>
            </div>
            <div className="collapse navbar-collapse" id="realdeal_main_menu">
              <ul className="navbar-nav menu-open readeal-top">
                {/* <li><Link to="/home">Manage Rentals</Link></li> */}
                {/* <li><Link to="/home">Advertise</Link></li> */}
                <li className="menu-item-has-children">
                  <a href="#">Help</a>
                  <ul className="sub-menu">
                    <li><Link to="/availavbe-property">Mortgage Calculator</Link></li>
                    <li><Link to="/recent-properties">Budget Calculator</Link></li>
                  </ul>
                </li>
                {/* <li><Link to="/About">Sign in</Link></li> */}

                <li>
                  <Link onClick={(e) => !cart?.length && e.preventDefault()} to="/cart" >
                    <Tooltip title={cart?.length ? "Cart" : "Cart is empty"}>
                      <IconButton aria-label="cart">
                        <Badge badgeContent={cart?.length} color="primary">
                          <ShoppingCartIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Link>
                </li>

                <li>
                  <Link onClick={(e) => !wishlist?.length && e.preventDefault()} to="/favorite-properties" >
                    <Tooltip title={wishlist?.length ? "Favorite Properties" : "You have no favorite properties"}>
                      <IconButton aria-label="cart">
                        <Badge badgeContent={wishlist?.length} color="primary">
                          <FavoriteIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Link>
                </li>

                <li><Button variant="contained"
                  onClick={(e) => {
                    setOpenModal(true)
                  }}>Skill Assignment</Button></li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <SkillAssignments userID="" open={openModal} setOpen={handleChange} partName={"zillow"} />
    </div>
  )
}


export default Navbar