import React, { useState } from 'react';
import Navbar from './global-components/navbar';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { emptyCart, removeCart } from '../redux-features/cart/cartSlice'
import { setWishList, removeWishList } from '../redux-features/wishlist/wishlistSlice'
import toast from 'react-hot-toast'
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import {
    Grid,
    Checkbox,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';


const Cart = () => {
    const dispatch = useDispatch()
    const wishlist = useSelector(state => state.wishlist.products)
    const cart = useSelector(state => state.cart.products)
    const [open, setOpen] = useState(false)
    function format(x) {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function totalAmount() {
        let total = 0
        cart.map((obj) => {
            total += obj.price
        })
        return format(total)
    }

    return <div>
        <Navbar />
        <Grid className="container" container sx={{ mt: 9 }}>

            <div className="row w-100">
                <div className="col-8">
                    <div className="row d-flex justify-content-center w-100 mt-2">
                        <h4 className="text-center">Your Cart</h4>
                    </div>
                    <div className="row w-100 d-flex justify-content-end">
                        <Button
                            disabled={!cart?.length}
                            onClick={() => setOpen(true)}
                            sx={{ backgroundColor: "rgb(251, 120, 92)", width: 'fit-content' }}
                            variant="contained">
                            Empty Cart ?
                        </Button>
                    </div>
                    <div className="row my-3 w-100">
                        {
                            !cart?.length ?
                                <p style={{ width: '100%' }} className="mb-0 ml-4 text-center">
                                    Your cart is empty. If you want to add properties <Link style={{ textDecoration: 'underline', color: '#616ded' }} to="/search-list?type=buy">click here</Link>
                                </p>
                                :
                                cart?.map((item) => {
                                    let foundInWish = wishlist?.find(obj => obj._id === item._id)
                                    return (
                                        <div id={item?._id} key={item?._id} className="col-md-12 col-sm-12 pr-1 mob_search_card mt-3" >
                                            <Card className="search_hover p-2" style={{ height: '100%' }}>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <CardMedia
                                                            component="img"
                                                            width="100%"
                                                            height="120px"
                                                            image={item?.photos?.[0]?.url && item?.photos?.[0]?.url}
                                                            alt="image"
                                                            className="tumb_img"
                                                        />
                                                    </div>
                                                    <div className="col-6 d-flex align-items-center">
                                                        <CardContent className="content_card_s">
                                                            <Typography gutterBottom variant="h6" component="div">
                                                                ${format(item?.price)} {item?.name}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item?.bedrooms} bds | {item?.bathrooms} ba | {format(item?.livingArea?.toString())} sqft - {item?.homeStatus === "FOR_SALE" ? "House for sale " : 'Rental House '}
                                                                {item?.address?.streetAddress}, {item?.address?.city}, {item?.address?.state}, {item?.address?.zipcode}
                                                            </Typography>
                                                        </CardContent>
                                                    </div>
                                                    <div className="col-3 d-flex align-items-end justify-content-end">
                                                        <Button
                                                            onClick={() => {
                                                                dispatch(removeCart(item?._id))
                                                                toast.success("Removed from Cart")
                                                            }}
                                                            sx={{ backgroundColor: "rgb(251, 120, 92)" }}
                                                            variant="contained">{"Remove"}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Checkbox className="check_box_faverate"
                                                    onChange={(e) => {
                                                        if (e.target.checked && !foundInWish) {
                                                            dispatch(setWishList(item))
                                                            toast.success("Added to Wishlist")
                                                            return
                                                        }
                                                        dispatch(removeWishList(item?._id))
                                                        toast.success("Removed from Wishlist")
                                                    }}
                                                    checked={foundInWish}
                                                    icon={<FavoriteBorder className="check_box_border" />} checkedIcon={<Favorite className="text-black" />}
                                                />
                                            </Card>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
                <div className="col-4">
                    <Card sx={{ mt: 10, p: 2 }}>
                        <CardContent>
                            <div style={{
                                fontSize: 15,
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <span>{`Total(${cart?.length} Items): `}</span>
                                <span>${totalAmount()}</span>
                            </div>
                            <div className='w-100 mt-5 d-flex justify-content-center'>
                                <Link to="/checkout">
                                    <Button
                                        disabled={!cart?.length}
                                        sx={{ backgroundColor: '#1976d2', padding: '10px 25px' }}
                                        variant="contained">
                                        Place Order
                                    </Button>
                                </Link>

                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>

        </Grid>

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to empty cart ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => setOpen(false)}>No</Button>
                <Button
                    sx={{ backgroundColor: "rgb(251, 120, 92)" }}
                    variant="contained"
                    onClick={() => {
                        setOpen(false)
                        dispatch(emptyCart())
                        toast.success("Cart is empty!")
                    }}
                    autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}

export default Cart

