import React ,{useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function BasicModal(props) {

    const [item,setItem] = useState(props)
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    return (
        <div>
            <div className="col-lg-12">
                <div className="single-feature">
                    <div className="thumb">
                        <img src={item.photos?.[0]?.url} alt="img" />
                    </div>
                    <div className="details">
                        <p className="author"><i className="fa fa-user" /> {item.association.name}</p>
                        <h6 className="title readeal-top"><Link to={`/property-details/${item.mlsId}`}>{item.address.crossStreet}</Link></h6>
                        <h6 className="price"><span>$ {(item.listPrice).toLocaleString()}</span></h6>
                        <ul className="info-list">
                            <li><i className="fa fa-bed" /> <span >0{item.property.bedrooms} Bed </span></li>
                            <li><i className="fa fa-bath" /> <span >0{item.property.bathsFull} Bath </span></li>

                            <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.property.area} sq.</li>
                        </ul>
                        <ul className="contact-list">
                            <li><a className="phone" href={"tel:+47"}><i className="fa fa-phone" /></a></li>
                            <li><a className="message" href="sms:+911234567890"><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                            <li className="readeal-top"><Link className="btn btn-yellow" to={`/property-details/${item.mlsId}`} >View Details</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    );
}