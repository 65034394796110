import React, { Component, useEffect, useState } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Loading from '../common/loading';
import { useQuery, useQueryClient } from 'react-query';
import { getFeaturedProperties } from '../../api/api';

export default function Featured(props) {
  const queryClient = useQueryClient();
  const { isLoding, isSuccess, data: featuredProperties } = useQuery(['properties'], () => getFeaturedProperties())

  let publicUrl = process.env.PUBLIC_URL + '/'
  let imagealt = 'image'
  let data = sectiondata.featuredproperties
  let Customclass = 'pd-top-60'

  if (isLoding) {
    return (<Loading />)
  }
  else {
    return <div className={"featured-area  " + Customclass}>
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title">{data.title}</h2>
        </div>
        <div className="row justify-content-center">
          {/* <div className="col-xl-6 col-lg-8">
                <div className="single-leading-feature">
                  <div className="slf-overlay" />
                  <div className="thumb">
                    <img src={publicUrl+data.firstitem.image} alt={ imagealt } />
                  </div>
                  <div className="details">
                    <h4 className="title readeal-top"><Link to={ data.firstitem.url  }>{ data.firstitem.title }</Link></h4>
                    <h5 className="price">{ data.firstitem.price }</h5>
                    <span>{ data.firstitem.content }</span>
                  </div>
                </div>
              </div> */}
          {isSuccess ?
            featuredProperties.map((item, i) =>
              <div key={i} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="single-feature">
                  <div className="thumb">
                    <img src={item.photos?.[0]?.url} alt="img" />
                  </div>
                  <div className="details">
                    <p className="author"><i className="fa fa-user" /> {item.association.name}</p>
                    <h6 className="title readeal-top"><Link to={`/property-details/${item.mlsId}`}>{item.address.crossStreet}</Link></h6>
                    <h6 className="price"><span>$ {(item.listPrice).toLocaleString()}</span></h6>
                    <ul className="info-list">
                      <li><i className="fa fa-bed" /> <span >0{item.property.bedrooms} Bed </span></li>
                      <li><i className="fa fa-bath" /> <span >0{item.property.bathsFull} Bath </span></li>

                      <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.property.area} sq.</li>
                    </ul>
                    <ul className="contact-list">
                      <li><a className="phone" href={"tel:+47"}><i className="fa fa-phone" /></a></li>
                      <li><a className="message" href="sms:+911234567890"><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                      <li className="readeal-top"><Link className="btn btn-yellow" to={`/property-details/${item.mlsId}`} >View Details</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            ) :
            <></>}

        </div>
      </div>
    </div>

  }

}