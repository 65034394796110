import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query'
import { fetchProperties } from '../../api/api'
import Loading from '../common/loading';

export default function RecentProperties() {
  const queryClient = useQueryClient()
  const { isLoading, isSuccess, data: allProperties } = useQuery(['all-properties', 1, -1], () => fetchProperties(1, -1))

  let publicUrl = process.env.PUBLIC_URL + '/'
  let imagealt = 'image'
  let data = sectiondata.recentproperties
  if (isLoading || !isSuccess) {
    return <Loading />
  }
  else
    return (
      <div className="properties-area pd-top-92">
        <div className="container">
          <div className="section-title">
            <h2 className="title">All Properties</h2>
            {/* <Link className="btn-view-all" to={ data.btnurl }>View All</Link> */}
          </div>
          <div className="row">
            {isSuccess ?
              allProperties?.data?.map((item, i) =>
                <div key={i} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="single-feature">
                    <div className="thumb">
                      <img src={item.photos?.[0]?.url} alt="img" />
                    </div>
                    <div className="details">
                      {/* <p className="author"><i className="fa fa-user" /> {item.association.name}</p> */}
                      <h6 className="title readeal-top"><Link to={`/property-details/${item.mlsId}`}>{item.address.streetAddress}</Link></h6>
                      <h6 className="price"><span>$ {item.price}</span></h6>
                      <ul className="info-list">
                        <li><i className="fa fa-bed" /> <span >0{item.bedrooms} Bed </span></li>
                        <li><i className="fa fa-bath" /> <span >0{item.bathrooms} Bath </span></li>

                        <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.livingArea} sq.</li>
                      </ul>
                      <ul className="contact-list">
                        <li><a className="phone" href={"tel:+47"}><i className="fa fa-phone" /></a></li>
                        <li><a className="message" href="sms:+911234567890"><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                        {/* <li className="readeal-top"><Link className="btn btn-yellow" to={`/property-details/${item.mlsId}`} >View Details</Link></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              ) :
              <></>}
          </div>
        </div>
      </div>
    )
}