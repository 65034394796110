import React, { useEffect } from 'react';
import Navbar from './global-components/navbar';
import { useSelector, useDispatch } from 'react-redux';
import { setCart, removeCart } from '../redux-features/cart/cartSlice'
import { removeWishList } from '../redux-features/wishlist/wishlistSlice'
import toast from 'react-hot-toast'
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import {
    Grid,
    Checkbox,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
    Button
} from '@mui/material';

const FavoriteProperties = () => {
    const dispatch = useDispatch()
    const wishlist = useSelector(state => state.wishlist.products)
    const cart = useSelector(state => state.cart.products)
    function format(x) {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return <div>
        <Navbar />
        <Grid className="container" container sx={{ mt: 9 }}>
            <div className="row d-flex justify-content-center w-100 mt-2">
                <h4 className="text-center">Favorite Properties</h4>
            </div>
            <div className="row my-3 w-100">
                {
                    !wishlist?.length ?
                        <p style={{ width: '100%' }} className="mb-0 ml-4 text-center">No resultt fount!</p>
                        :
                        wishlist?.map((item) => {
                            console.log("🚀 ~ wishlist?.map ~ item:", item)
                            let foundInCart = cart?.find(obj => obj._id === item?._id)
                            return (
                                <div id={item?._id} key={item?._id} className="col-md-12 col-sm-12 pr-1 mob_search_card mt-3" >
                                    <Card className="search_hover p-2" style={{ height: '100%' }}>
                                        <div className="row">
                                            <div className="col-3">
                                                <CardMedia
                                                    component="img"
                                                    width="100%"
                                                    height="200px"
                                                    image={item?.photos?.[0]?.url}
                                                    alt="image"
                                                    className="tumb_img"
                                                />
                                            </div>
                                            <div className="col-6 d-flex align-items-center">
                                                <CardContent className="content_card_s">
                                                    <Typography gutterBottom variant="h6" component="div">
                                                        ${format(item?.price)} {item?.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item?.bedrooms} bds | {item?.bathrooms} ba | {format(item?.livingArea?.toString())} sqft - {item?.homeStatus === "FOR_SALE" ? "House for sale " : 'Rental House '}
                                                        {item?.address?.streetAddress}, {item?.address?.city}, {item?.address?.state}, {item?.address?.zipcode}
                                                    </Typography>
                                                </CardContent>
                                            </div>
                                            <div className="col-3 d-flex align-items-end justify-content-end">
                                                <Button
                                                    onClick={() => {
                                                        if (foundInCart) {
                                                            dispatch(removeCart(item?._id))
                                                            toast.success("Removed from Cart")
                                                            return
                                                        }
                                                        dispatch(setCart(item))
                                                        toast.success("Added to Cart")
                                                    }}
                                                    sx={{ backgroundColor: foundInCart ? "rgb(251, 120, 92)" : '#1976d2' }}
                                                    variant="contained">{foundInCart ? "Remove from Cart" : "Add to Cart"}
                                                </Button>
                                            </div>
                                        </div>
                                        <Checkbox className="check_box_faverate"
                                            onChange={(e) => {
                                                dispatch(removeWishList(item?._id))
                                                toast.success("Removed from Wishlist")
                                            }}
                                            checked={true}
                                            icon={<FavoriteBorder className="check_box_border" />} checkedIcon={<Favorite className="text-black" />}
                                        />
                                    </Card>
                                </div>
                            )
                        })
                }
            </div>
        </Grid>
    </div>
}

export default FavoriteProperties

