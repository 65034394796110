import React, { useState, useEffect } from 'react'
import { IconButton } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getCard, freezCard, } from '../../../api/index'
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast'
import moment from 'moment'
import Alert from '@mui/material/Alert';
import CardSVG from './CardSVG'

import closeEyeIcon from './new-svgs/eye_closed.svg'
import eyeIcon from './new-svgs/eye.svg'
import disabledEyeIcon from './new-svgs/eye-disabled.svg'
import freezIcon from './new-svgs/freez-icon.svg'
import freezedIcon from './new-svgs/freezed-icon.svg'
import disabledFreezIcon from './new-svgs/freez-disabled.svg'


export default function Card() {
    const queryClient = useQueryClient();
    const { isLoading, data } = useQuery("get-cards", getCard)
    const [reveal, setReveal] = useState(false)
    const [cardData, setCardData] = useState([])
    const { mutate: cardFreez } = useMutation("freez-card", freezCard, {
        onSuccess: () => {
            queryClient.invalidateQueries("get-cards")
            toast.dismiss()
            toast.success("Done")
            setReveal(false)
        }
    })

    useEffect(() => {
        if (!isLoading) {
            setCardData(data?.data)
        }
    }, [isLoading, data])

    const dateFormate = (date) => {
        let dat = moment(date).format("M/YY")
        return (dat)
    }

    function currencyNumber(x) {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const returnName = () => {
        const { userID } = cardData || "0000";
        if (!userID) return "Anonymous"
        const { firstName, lastName } = userID;

        if (!firstName) return "Anonymous"
        const names = [firstName, lastName].toString()

        if (names.toString().length > 18) return names.toString().replace(",", " ").slice(0, 16) + "..."
        return firstName + " " + lastName
    }

    if (isLoading) {
        return (
            <div className="row d-flex justify-content-center align-content-center">
                <CircularProgress size={60} />
            </div>
        )
    } else
        return (
            <>
                <div className="row">
                    {
                        cardData?.isBlocked &&
                        <Alert style={{ width: '100%' }} severity="error">Dear user, Your card has been blocked !</Alert>
                    }
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                            <div className="balance-label">Balance</div>
                            <div className="amount">${currencyNumber(parseFloat(cardData?.currentAmount))}</div>
                        </div>
                        <div className="visa_card">
                            <CardSVG
                                name={returnName()}
                                cardNumber={cardData?.cardNumber}
                                cvv={cardData?.cvv}
                                expiry={dateFormate(cardData?.expiryDate)}
                                reveal={reveal}

                            />
                        </div>

                        <div className="card-details balance">
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="visa_btn">
                                    <IconButton
                                        disabled={cardData?.tempFreeze || cardData?.isBlocked}
                                        onClick={() => setReveal(!reveal)}
                                    >
                                        {
                                            reveal ?
                                                <img width="30px" src={closeEyeIcon} alt="" />
                                                : (cardData?.tempFreeze || cardData?.isBlocked) ?
                                                    <img width="30px" src={disabledEyeIcon} alt="" />
                                                    :
                                                    <img width="30px" src={eyeIcon} alt="" />
                                        }
                                    </IconButton>
                                    <h6>{reveal ? "Hide Details" : "Reveal Details"}</h6>
                                </div>
                                <div className="visa_btn">
                                    <IconButton onClick={() => {
                                        toast.loading("Loading...")
                                        cardFreez()
                                    }}
                                        className='freez-btn'
                                        disabled={cardData?.isBlocked}
                                    >
                                        {
                                            cardData?.isBlocked ?
                                                <img width="30px" src={disabledFreezIcon} alt="" />
                                                : cardData?.tempFreeze ?
                                                    <img width="30px" src={freezedIcon} alt="" />
                                                    :
                                                    <img width="30px" src={freezIcon} alt="" />
                                        }
                                    </IconButton>
                                    <h6>{cardData?.tempFreeze ? "Unfreeze Card" : "Freeze Card"}</h6>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
}
