import axios from 'axios'
export const getAssignments = async (type) => {
    const res = await axios.get(`${process.env.REACT_APP_API_LINK}/skill-assignment?type=${type}`,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`,
                'x-api-key': process.env.REACT_APP_X_API_KEY
            }
        });
    return res.data;
}