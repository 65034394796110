import React from "react";
import CheckIcon from "@mui/icons-material/Check";

const Sell_sec2 = () => {
  return (
    <div
      className="container-fluid sell_1row"
      style={{ backgroundColor: "#e0f2ff" }}
    >
      <div className="container py-5">
        <div className="loans_head pb-3">
          <h4>Home Loans</h4>
          <span>ADVERTISEMENT</span>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5 col-12">
            <h5 className="font-weight-bold pb-3">
              Refinance with Zillow Home Loans
            </h5>
            <p className="para_sell w-100">
              If you're not ready to sell, see if you can save money every month
              by refinancing with Zillow Home Loans. You get competitive rates
              from a lender you can trust, and loan options suited to your
              unique needs.
            </p>
            <a href="#" className="start_save">
              Start saving
            </a>
          </div>
          <div className="col-md-5 col-12">
            <h5 className="font-weight-bold pb-3">
              Why refinance with Zillow Home Loans
            </h5>
            <ul className="correct_ul">
              <li>
                <CheckIcon className="check_icon1" />
              </li>
              <li>
                <p>Competitive rates and fees</p>
              </li>
            </ul>
            <ul className="correct_ul">
              <li>
                <CheckIcon className="check_icon1" />
              </li>
              <li>
                <p>Transparent process</p>
              </li>
            </ul>
            <ul className="correct_ul">
              <li>
                <CheckIcon className="check_icon1" />
              </li>
              <li>
                <p>Top-rated, experienced loan officers</p>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-12">
            <img src="/home//assets/img//PiggyDesktop.png" alt="" />
          </div>
          <p className="agent_list_p text-center pt-4 mb-0">
            Stickball Home Loans is an Equal Housing Lender, NMLS ID# 10287
           <a href="#"> (www.nmlsconsumeraccess.org)</a> 10975 El Monte St., Overland Park, KS
            66211, (888) 852-2212. This is not <br /> a commitment to lend. This is an
            advertisement.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sell_sec2;
