import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    products: []
}

export const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        setWishList: (state, action) => {
            let temp = state?.products
            temp.push(action.payload)
            state.products = temp
        },
        removeWishList: (state, action) => {
            state.products = state.products.filter(x => x?._id !== action.payload)
        }
    },

})

export const { setWishList, removeWishList } = wishlistSlice.actions

export default wishlistSlice.reducer