import React, { Component, useEffect, useState } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchProperties, getFilteredProperties } from "../../api/api";
import Pagination from "@mui/material/Pagination";
import { useParams } from "react-router-dom";
import SearchListFilters from "../common/serach-list-filters";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Modal, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Stack from "@mui/material/Stack";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Loading from "../common/loading";
import Slider from "@mui/material/Slider";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Form from "react-bootstrap/Form";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import TwentyFourMpSharpIcon from '@mui/icons-material/TwentyFourMpSharp';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { CircularProgress } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import Favorite from '@mui/icons-material/Favorite';
import toast from 'react-hot-toast'
import logoIcon from "../assets/logo.svg"


import { setCart, removeCart } from '../../redux-features/cart/cartSlice'
import { setWishList, removeWishList } from '../../redux-features/wishlist/wishlistSlice'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 90,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
};

const AnyReactComponent = ({ item, setProperty, setOpenm }) => {
  console.log("🚀 ~ AnyReactComponent ~ item:", item)
  const [isShown, setIsShown] = useState(false);

  function format(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return <>
    {!!isShown && <div key={item?._id + "))"} style={{
      height: 150,
      width: 150,
      backgroundColor: '#ffffff',
      position: 'absolute',
      bottom: 5,
      borderRadius: 10,
      left: -((150 / 2) - (20 / 2)),
      animation: "fadeIn 2s",
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      zIndex: 1000
    }}>
      <img
        style={{ height: 100, width: "100%" }}
        src={item?.photos?.[0]?.url} />

      <p>
        <b>${format(item?.price)}</b>
        {item?.bedrooms} bds | {item?.bathrooms} ba | {format(item?.livingArea?.toString())} sqft - {item?.homeStatus === "FOR_SALE" ? "House for sale " : 'Rental House '}
        {item?.address?.streetAddress}, {item?.address?.city}, {item?.address?.state}, {item?.address?.zipcode}
      </p>

    </div>}
    <div
      onClick={() => {
        setProperty(item)
        setOpenm(true)
      }}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      style={{
        height: 20, width: 20, backgroundColor: isShown ? '#84f542' : '#fb785c', borderRadius: 20,
        border: '1px solid #fff'

      }} >


    </div>
  </>
}

const minDistance = 10;
export default function SearchList() {
  const dispatch = useDispatch()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Example: Get the value of the "id" query parameter
  const type = queryParams.get('type');
  // modal
  const [openm, setOpenm] = useState(false);
  const [property, setProperty] = useState({
    photos: []
  })
  const cart = useSelector(state => state.cart.products)
  const wishlist = useSelector(state => state.wishlist.products)
  const [page, setPage] = useState(1)
  const [page1, setPage1] = useState(1)
  const [data, setData] = useState({})
  const [avg, setAvg] = useState(0.00)
  const { isSuccess, data: pData, } = useQuery(["all-properties", page, 200], () => fetchProperties(page, 200));
  const { isLoading, mutate: mutateFilter } = useMutation(['apply-filter', page1, 200], (data) => getFilteredProperties(data, page1, 200), {
    onSuccess: res => {
      setData({
        ...res,
        data: type === "buy" ?
          res?.data?.filter(item => item?.homeStatus === "FOR_SALE")
          : type === "rent" ?
            res?.data?.filter(item => item?.homeStatus !== "FOR_SALE")
            : res?.data
      })
    }
  })

  useEffect(() => {
    if (isSuccess) {
      setData({
        ...pData,
        data: type === "buy" ?
          pData?.data?.filter(item => item?.homeStatus === "FOR_SALE")
          : type === "rent" ?
            pData?.data?.filter(item => item?.homeStatus !== "FOR_SALE")
            : pData?.data
      })
    }
  }, [isSuccess, pData, type])
  useEffect(() => {
    let total = 0.00
    if (!!data?.data?.length) {
      data.data.map(item => total += parseFloat(item?.price))
    }
    setAvg(total / data?.data?.length)
  }, [data])

  const handleOpenm = () => {
    setOpenm(true);
  };
  const handlePagiantion = (event, value) => {
    setPage(value);
  }
  const handlePagiantion1 = (event, value) => {
    setPage1(value);
  }
  const handleClosem = () => {
    setOpenm(false);
  };

  // filter-modal
  const [openf, setOpenf] = useState(false);
  const handleOpenf = () => setOpenf(true);
  const handleClosef = () => setOpenf(false);
  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);
  const [readMore, setReadMore] = useState(false);
  const [value1, setValue1] = useState([0, 5000000]);
  const [beds, setBeds] = useState("any");
  const [baths, setBaths] = useState("any");
  const [days, setDays] = useState("1");
  const [priceStart, setPriceStart] = useState(0)
  const [priceEnd, setPriceEnd] = useState(5000000)
  const [homeType, setHomeType] = useState("")
  const [filter, setFilter] = useState(false)

  const handleFilters = async () => {
    let temp = {
      startPrice: priceStart,
      endPrice: priceEnd,
      beds: beds !== "any" ? beds : "",
      bathrooms: baths !== "any" ? baths : "",
      homeType: homeType,
      daysOnZillow: days !== "any" ? days : ""
    }
    try {
      mutateFilter(temp)
      setOpenf(false)
      setFilter(true)
    } catch (e) {
      console.log("Error: ", e)
    }

  }
  const handleChangeSlider = (event, newValue, activeThumb) => {
    setPriceStart(newValue[0])
    setPriceEnd(newValue[1])
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };
  //
  function format(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function valuetext(value) {
    return "$" + format(value);
  }


  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  if (!isSuccess) {
    return <Loading />;
  } else
    return (
      <div className="search-page-wrap pd-top-71">
        <div className="search-container">
          <div className="container-fluid pl-0">
            <div className="row justify-content-center border-top">
              <div className="col-xl-5 col-lg-5 pr-0">
                <div className="filter-single-input">
                  <input
                    type="text"
                    placeholder="California"
                    className="form-control"
                  />
                  <Link className="filter-icon-input" to="#">
                    <i className="fa fa-search"></i>
                  </Link>
                </div>
                <div className="search-list-page-map" style={{ height: '100%' }}>
                  {!!data?.data?.length &&
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                      defaultCenter={{ lat: data?.data[data?.data?.length / 2]?.latitude, lng: data?.data[data?.data?.length / 2]?.longitude }}
                      defaultZoom={8}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
                    >
                      {data?.data.map(val => <AnyReactComponent
                        lat={val.latitude}
                        lng={val.longitude}
                        item={val}
                        setProperty={setProperty}
                        setOpenm={setOpenm}
                      />)}

                    </GoogleMapReact>}
                </div>
              </div>

              <div className="col-xl-7 col-lg-7">
                <Button
                  variant="outlined"
                  className="filter_button"
                  onClick={handleOpenf}
                >
                  <img
                    className="mr-3"
                    src={publicUrl + "assets/img/icons/18.png"}
                    alt={imagealt}
                  />
                  Filters
                </Button>
                <div className="_Yscroll">
                  <div className="property_text">
                    <h5>Stickball properties</h5>
                    <div className="sort_cali_para">
                    </div>
                  </div>

                  {
                    filter ?
                      isLoading ?
                        <>
                          <div className="row d-flex justify-content-center">
                            <CircularProgress />
                          </div>
                        </>
                        :
                        <>
                          <div className="row my-3">
                            {
                              !data?.data?.length ?
                                <p className="mb-0 ml-4">No resultt fount!</p>
                                :
                                data?.data?.map((item) => {
                                  let foundInWish = wishlist?.find(obj => obj._id === item._id)
                                  return (
                                    <div id={item?._id} key={item?._id} className="col-md-6 col-sm-6 pr-1 mob_search_card" >
                                      <Card className="mr-3 search_hover mb-3">
                                        <CardMedia
                                          component="img"
                                          height="140"
                                          image={item?.photos?.[0]?.url}
                                          onClick={() => {
                                            setProperty(item)
                                            setOpenm(true)
                                          }}
                                          alt="image"
                                          className="tumb_img"
                                        />
                                        <Checkbox className="check_box_faverate"
                                          onChange={(e) => {
                                            if (e.target.checked && !foundInWish) {
                                              dispatch(setWishList(item))
                                              toast.success("Added to Wishlist")
                                              return
                                            }
                                            dispatch(removeWishList(item?._id))
                                            toast.success("Removed from Wishlist")
                                          }}
                                          checked={foundInWish}
                                          icon={<FavoriteBorder className="check_box_border" />} checkedIcon={<Favorite className="text-black" />} />
                                        {/* <FavoriteBorder className="chip_card" /> */}
                                        <CardContent className="content_card_s">
                                          <Typography gutterBottom variant="h6" component="div">
                                            ${format(item?.price)}
                                          </Typography>
                                          <Typography variant="body2" color="text.secondary">
                                            {item?.bedrooms} bds | {item?.bathrooms} ba | {format(item?.livingArea?.toString())} sqft - {item?.homeStatus === "FOR_SALE" ? "House for sale " : 'Rental House '}
                                            {item?.address?.streetAddress}, {item?.address?.city}, {item?.address?.state}, {item?.address?.zipcode}
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </div>
                                  )
                                })
                            }
                          </div>
                          {isSuccess ? (
                            <>
                              <Pagination
                                count={data?.Totalpages || 0}
                                color="primary"
                                // onChange={(e) => console.log(e)}
                                className='d-flex justify-content-end' page={page1} onChange={handlePagiantion1}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      :
                      <>
                        <div className="row my-3">
                          {
                            !data?.data?.length ?
                              <p className="mb-0 ml-4">No resultt fount!</p>
                              :
                              data?.data?.map((item) => {
                                let foundInWish = wishlist?.find(obj => obj._id === item._id)
                                return (
                                  <div key={item?._id
                                  } className="col-md-6 col-sm-6 pr-1 mob_search_card" >
                                    <Card className="mr-3 search_hover mb-3">
                                      <CardMedia
                                        component="img"
                                        height="140"
                                        image={item?.photos?.[0]?.url}
                                        onClick={() => {
                                          setProperty(item)
                                          setOpenm(true)
                                        }}
                                        alt="image"
                                        className="tumb_img"
                                      />
                                      <Checkbox
                                        onChange={(e) => {
                                          if (e.target.checked && !foundInWish) {
                                            dispatch(setWishList(item))
                                            toast.success("Added to Wishlist")
                                            return
                                          }
                                          dispatch(removeWishList(item?._id))
                                          toast.success("Removed from Wishlist")
                                        }}
                                        checked={foundInWish}
                                        className="check_box_faverate" icon={<FavoriteBorder className="check_box_border" />} checkedIcon={<Favorite className="text-black" />} />
                                      <CardContent className="content_card_s">
                                        <Typography gutterBottom variant="h6" component="div">
                                          ${format(item?.price)}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                          {item?.bedrooms} bds | {item?.bathrooms} ba | {format(item?.livingArea?.toString())} sqft - {item?.homeStatus === "FOR_SALE" ? "House for sale " : 'Rental House '}
                                          {item?.address?.streetAddress}, {item?.address?.city}, {item?.address?.state}, {item?.address?.zipcode}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </div>
                                )
                              })
                          }
                        </div>
                        {isSuccess ? (
                          <>
                            <Pagination
                              count={data?.Totalpages || 0}
                              color="primary"
                              // onChange={(e) => console.log(e)}
                              className='d-flex justify-content-end' page={page} onChange={handlePagiantion}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                  }
                </div>
              </div>
              {/* modal */}
              <Dialog
                open={openm}
                onClose={handleClosem}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                fullScreen
              >
                <Box>
                  <Button onClick={handleClosem} sx={{ position: 'absolute', right: '10px', top: '5px', zIndex: 1000 }}>
                    <CloseIcon />
                  </Button>
                  <div className="row">
                    <div className="col-md-7 pr-0 col-12 p-2">
                      <img
                        src={property?.photos?.[0]?.url}
                        alt="image"
                        className="w-100"
                      />
                      <div className="row">
                        {
                          property?.photos?.map((photo, index) => {
                            return (
                              index !== 0 &&
                              <div key={photo?.url} className="col-md-6 pr-0">
                                <img
                                  src={photo?.url}
                                  alt="image"
                                  className="w-100 py-1"
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className="col-md-5 pl-0 col-12 p-2">
                      <nav className="navbar nav_modal">
                        <Link to="/">
                          <img
                            src={logoIcon}
                            alt="logo"
                          />
                        </Link>
                        {/* <div className="float-right link_nav">
                          <a href="#">Save</a>
                          <a href="#">Share</a>
                          <a href="#">
                            <MoreHorizIcon
                              style={{ verticalAlign: "bottom" }}
                            />
                            More
                          </a>
                        </div> */}
                      </nav>
                      <div className="px-3">
                        <p style={{ color: "#000" }}>
                          <span className="dollor">${format(property?.price)}</span>
                          <span className="fw_bold">{property?.bedrooms}</span> bd |
                          <span className="fw_bold">{format(property?.livingArea)}</span> sqft {property?.address?.streetAddress}, {property?.address?.city}, {property?.address?.state} {property?.address?.zipcode}
                        </p>
                        <p style={{ color: "#000" }}>
                          <span className="for_sale">
                            <FiberManualRecordIcon className="for_sale_icon" />
                            {property?.homeStatus === "FOR_SALE" ? "For Sale" : "For Rent"}
                          </span>
                          | Zestimate®: |
                          <span className="for_sale">${format(property?.price)}</span>
                        </p>
                        <p style={{ color: "#000" }}>
                          <span className="fw_bold">Est. payment : </span>
                          ${format(property?.price / 12)}/mo
                        </p>
                        <Stack spacing={3} direction="row" className="two_btn">
                          <Button variant="contained">Request a Tour</Button>
                          <Button
                            onClick={() => {
                              let foundInCart = cart?.find(obj => obj._id === property?._id)
                              if (foundInCart) {
                                dispatch(removeCart(property?._id))
                                toast.success("Removed from Cart")
                                return
                              }
                              dispatch(setCart(property))
                              toast.success("Added to Cart")
                            }}
                            sx={{ backgroundColor: cart?.find(obj => obj._id === property?._id) ? "rgb(251, 120, 92)" : '#1976d2' }}
                            variant="contained">{cart?.find(obj => obj._id === property?._id) ? "Remove from Cart" : "Add to Cart"}</Button>

                        </Stack>
                        <div className="my-3">
                          <ul className="over-view">
                            <li><ListAltIcon className="text-primary" /></li>
                            <li><p>Singlefamily</p></li>
                          </ul>
                          <ul className="over-view">
                            <li><CalendarMonthIcon className="text-primary" /></li>
                            <li><p>Built in {property?.yearBuilt}</p></li>
                          </ul>
                          <ul className="over-view">
                            <li><ThermostatIcon className="text-primary" /></li>
                            <li><p>Forced air, gas</p></li>
                          </ul>
                          <ul className="over-view">
                            <li><AcUnitIcon className="text-primary" /></li>
                            <li><p>Central</p></li>
                          </ul>
                          <ul className="over-view">
                            <li><TwentyFourMpSharpIcon className="text-primary" /></li>
                            <li><p>Garage - detached</p></li>
                          </ul>
                          <ul className="over-view">
                            <li><ImageAspectRatioIcon className="text-primary" /></li>
                            <li><p>{format(property?.livingArea)} sqft</p></li>
                          </ul>
                          <ul className="over-view">
                            <li><SquareFootIcon className="text-primary" /></li>
                            <li><p>${format((property?.price / property?.livingArea).toFixed(0))} price/sqft</p></li>
                          </ul>
                          <h4>Overview</h4>
                          <p className="overview-days"> <span> {property?.daysOnZillow} days </span> on Stickball</p>
                          <h4>Facts and features</h4>
                          <ul className="pl-4">
                            <li>Bedrooms: {property?.bedrooms}</li>
                            <li>Bathrooms: {property?.bathrooms}</li>
                          </ul>
                          <h4>Location</h4>
                          <ul className="pl-4">
                            <li>Street: {property?.address?.streetAddress} </li>
                            <li>City: {property?.address?.city} </li>
                            <li>State: {property?.address?.state} </li>
                            <li>Zip Code: {property?.address?.zipcode} </li>
                          </ul>
                          <h4>Estimated market value</h4>
                          <div className="text-center">
                            <p className="zestimate-para">Zestimate®</p>
                            <h4>${format(property?.price)}</h4>
                            <p className="estimate-sale">Estimated sales range: ${format(property?.price - 3000)} - ${format(property?.price + 3000)} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Dialog>
              {/* filter Modal */}
              <Modal
                open={openf}
                onClose={handleClosef}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openf}>
                  <Box className="filter_modal">
                    <div className="header_filter">
                      <CloseIcon
                        onClick={handleClosef}
                        className="filter_close_icon"
                      />
                      <h5>Filters</h5>
                    </div>
                    <div className="body_filter">
                      <h6>Price Range</h6>
                      <p>Avg Price is ${format(avg)}</p>
                      <Slider
                        getAriaLabel={() => "Minimum distance"}
                        value={value1}
                        onChange={handleChangeSlider}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        className="slider_filter"
                        min={0}
                        max={5000000}
                        disableSwap
                      />
                      <ul className="max_box_ul">
                        <li>
                          <div className="max_box">
                            <p>Min Price</p>
                            <h6>$ {format(priceStart)}</h6>
                          </div>
                        </li>
                        <li className="max_box_line">
                          <HorizontalRuleIcon className="line_ficon" />
                        </li>
                        <li>
                          <div className="max_box">
                            <p>Max Price</p>
                            <h6>$ {format(priceEnd)}</h6>
                          </div>
                        </li>
                      </ul>
                      <hr />
                      <div className="container">
                        <h6>Home Type</h6>
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5>House</h5>
                                <p>A Place all to yourself</p>
                              </li>
                            </ul>
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5>Room</h5>
                                <p>A Place all to yourself</p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5>Appartment</h5>
                                <p>A Place all to yourself</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <hr />
                      </div>
                      <h6>Bed & Bath</h6>

                      <div className="myDIV mt-4">
                        <h6 className="text-secondary">Beds</h6>
                        <button className={beds === "any" ? "btn ml-0 btn-active" : "btn ml-0"} onClick={() => setBeds("any")}>Any</button>
                        <button className={beds === "1" ? "btn btn-active" : "btn"} onClick={() => setBeds("1")}>1</button>
                        <button className={beds === "2" ? "btn btn-active" : "btn"} onClick={() => setBeds("2")}>2</button>
                        <button className={beds === "3" ? "btn btn-active" : "btn"} onClick={() => setBeds("3")}>3</button>
                        <button className={beds === "4" ? "btn btn-active" : "btn"} onClick={() => setBeds("4")}>4</button>
                        <button className={beds === "5" ? "btn btn-active" : "btn"} onClick={() => setBeds("5")}>5</button>
                        <button className={beds === "6" ? "btn btn-active" : "btn"} onClick={() => setBeds("6")}>6</button>
                        <button className={beds === "7" ? "btn btn-active" : "btn"} onClick={() => setBeds("7")}>7+</button>
                      </div>

                      <div className="myDIV mt-4">
                        <h6 className="text-secondary">Baths</h6>
                        <button className={baths === "any" ? "btn ml-0 btn-active" : "btn ml-0"} onClick={() => setBaths("any")}>Any</button>
                        <button className={baths === "1" ? "btn btn-active" : "btn"} onClick={() => setBaths("1")}>1</button>
                        <button className={baths === "2" ? "btn btn-active" : "btn"} onClick={() => setBaths("2")}>2</button>
                        <button className={baths === "3" ? "btn btn-active" : "btn"} onClick={() => setBaths("3")}>3</button>
                        <button className={baths === "4" ? "btn btn-active" : "btn"} onClick={() => setBaths("4")}>4</button>
                        <button className={baths === "5" ? "btn btn-active" : "btn"} onClick={() => setBaths("5")}>5</button>
                        <button className={baths === "6" ? "btn btn-active" : "btn"} onClick={() => setBaths("6")}>6</button>
                        <button className={baths === "7" ? "btn btn-active" : "btn"} onClick={() => setBaths("7")}>7+</button>

                      </div>
                      <hr />
                      <div className="container">
                        <h6>Amenities</h6>
                        <p className="essent">Essentials</p>
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5 style={{ lineHeight: "2" }}>Wifi</h5>
                              </li>
                            </ul>
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5 style={{ lineHeight: "2" }}>Dryer</h5>
                              </li>
                            </ul>
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5 style={{ lineHeight: "2" }}>
                                  Air Conditioning
                                </h5>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5 style={{ lineHeight: "2" }}>Kitchen</h5>
                              </li>
                            </ul>
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5 style={{ lineHeight: "2" }}>Washer</h5>
                              </li>
                            </ul>
                            <ul className="check_ul">
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    className="checkbox_icon"
                                  />
                                </FormGroup>
                              </li>
                              <li>
                                <h5 style={{ lineHeight: "2" }}>Heating</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {
                          !readMore && <a href="#" onClick={() => setReadMore(true)} className="show-more">show more</a>
                        }

                        {readMore ?
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      Dedicated Workspace
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>TV</h5>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      Hair Dryer
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Iron</h5>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <p className="essent">Views</p>
                            <div className="row">
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      Mountain
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Ocean</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>City</h5>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      Lake
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Park</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Garden</h5>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <p className="essent">Additional Features</p>
                            <div className="row">
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      Pool
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Free Parking</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>crib</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>BBQ Grill</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Gym</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Hot Tub</h5>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      Basement
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Single Story Only</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>pet Friendly</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Break Fast</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Smoking Allowed</h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>EV Charger</h5>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <p className="essent">Safety</p>
                            <div className="row">
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      Smoke Alerm
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Carbon Monoxide Alarm</h5>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6">
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>
                                      CCTV
                                    </h5>
                                  </li>
                                </ul>
                                <ul className="check_ul">
                                  <li>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox disabled />}
                                        className="checkbox_icon"
                                      />
                                    </FormGroup>
                                  </li>
                                  <li>
                                    <h5 style={{ lineHeight: "2" }}>Anti Theft Alarm</h5>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </>
                          : null}
                        {
                          readMore && <a href="#" onClick={() => setReadMore(false)} className="show-more"> show less</a>
                        }


                        <hr />
                      </div>
                      <h6>Days on Stickball</h6>
                      <div className="myDIV mt-4">
                        <button className={days === "1" ? "btn ml-0 btn-active" : "btn ml-0"} onClick={() => setDays("1")}>1</button>
                        <button className={days === "7" ? "btn btn-active" : "btn"} onClick={() => setDays("7")}>7</button>
                        <button className={days === "14" ? "btn btn-active" : "btn"} onClick={() => setDays("14")}>14</button>
                        <button className={days === "1 month" ? "btn btn-active" : "btn"} onClick={() => setDays("1 month")}>1 month</button>
                        <button className={days === "3 month" ? "btn btn-active" : "btn"} onClick={() => setDays("3 month")}>3 month</button>
                        <button className={days === "6 month" ? "btn btn-active" : "btn"} onClick={() => setDays("6 month")}>6 month</button>
                        <button className={days === "1year" ? "btn btn-active" : "btn"} onClick={() => setDays("1year")}>1year</button>
                      </div>
                      <hr />
                      <h6>Keywords</h6>
                      <Form>
                        <Form.Group className="mt-4">
                          <Form.Control
                            as="textarea"
                            rows={1}
                            placeholder="MSL#, yard, etc..."
                          />
                        </Form.Group>
                      </Form>
                      <hr />

                      <Stack
                        spacing={3}
                        direction="row"
                        className="filter_last_btn mb-3"
                      >
                        <Button className="btn rest_bg" onClick={() => {
                          setValue1([0, 5000000])
                          setBeds("any")
                          setBaths("any")
                          setDays("any")
                          setPriceStart(0)
                          setPriceEnd(5000000)
                        }}>
                          rest all filters
                        </Button>
                        <Button className="btn apply_bg" onClick={() => handleFilters()}>Apply</Button>
                        <Button className="btn save_bg" onClick={handleOpens}>
                          Save
                        </Button>
                      </Stack>
                    </div>
                  </Box>
                </Fade>
              </Modal>
              {/* Filter inner modal */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={opens}
                onClose={handleCloses}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={opens}>
                  <Box className="ineer_modal">
                    <div className="header_inner">
                      <h5>Save Search</h5>
                      <CloseIcon
                        onClick={handleCloses}
                        className="inner_close_icon"
                      />
                    </div>
                    <div className="inner-body">
                      <h5>Almost done...</h5>
                      <Form>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label className="lable_email">Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="example@abc.com"
                            className="input_inner"
                          />
                        </Form.Group>
                        <Button className="btn apply_bg form-control submit">
                          Submit
                        </Button>
                        <p>
                          By Submiting, I accept Stickball's
                          <a href="#"> term of use.</a>
                        </p>
                      </Form>
                      <hr />
                      <h6 className="text-center mt-4">Or connect with:</h6>
                      <Button
                        className="btn apple-btn form-control mt-4"
                        startIcon={<AppleIcon className="appleicon" />}
                      >
                        Continue with Apple
                      </Button>
                      <Button
                        className="btn facebook-btn form-control mt-4"
                        startIcon={
                          <FacebookOutlinedIcon className="appleicon" />
                        }
                      >
                        Continue with Facebook
                      </Button>
                      <Button
                        className="btn google-btn form-control my-4"
                        startIcon={<GoogleIcon className="appleicon" />}
                      >
                        Continue with Google
                      </Button>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            </div>
          </div>
        </div>
      </div >
    );
}
