
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import cartReducer from '../redux-features/cart/cartSlice'
import wishlistReducer from '../redux-features/wishlist/wishlistSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


const rootPersistConfig = {
    key: 'root',
    storage,
}

const userPersistConfig = {
    key: 'cart',
    storage,
}
const wishListPersistConfig = {
    key: 'wishlist',
    storage,
}

const rootReducer = combineReducers({
    cart: persistReducer(userPersistConfig, cartReducer),
    wishlist: persistReducer(wishListPersistConfig, wishlistReducer),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)


export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

export const persistor = persistStore(store)
