import React from 'react'
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

const Sell_sec1 = () => {
  return (
    <div>
            <div className="container">
         <div className="row sell_1row">
          <div className="col-md-8">
            <div className="sell_1col">
              <h2>Sell traditionally with an agent</h2>
              <p>
                When you work with a real estate agent, you'll get selling
                support at every step, <br /> from prepping and listing your
                home to marketing that gets buyers in the door.
              </p>
              <Button variant="contained" className="mt-5 ">
                Find an agent
              </Button>
              <br />
              <a href="#">Learn how to choose an agent</a>
            </div>
          </div>
          <div className="col-md-4">
            <img src="/home//assets/img//Agent_House.svg" alt="" />
          </div>
        </div>
        <div className="row tab_scr">
          <div className="col-md-4 col-12">
            <h5 className="font-weight-bold pb-3">Why sell traditionally</h5>
            <ul className="correct_ul">
              <li>
                <CheckIcon className="check_icon" />
              </li>
              <li>
                <p>Potential for bidding wars</p>
              </li>
            </ul>
            <ul className="correct_ul">
              <li>
                <CheckIcon className="check_icon" />
              </li>
              <li>
                <p>Access to local market expertise</p>
              </li>
            </ul>
            <ul className="correct_ul">
              <li>
                <CheckIcon className="check_icon" />
              </li>
              <li>
                <p>Get help negotiating and reviewing offers</p>
              </li>
            </ul>
            <ul className="correct_ul">
              <li>
                <CheckIcon className="check_icon" />
              </li>
              <li>
                <p>Navigate a stressful process with a dedicated guide</p>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-12">
            <h5 className="font-weight-bold pb-3">How to sell traditionally</h5>
            <p className="para_sell">
              Learn more about the process of
              <a href="#"> selling your house with a listing agent.</a> If this
              is the best route for you, interview agents and select a
              professional who will meet your expectations. Your agent will then
              guide you through the steps of
              <a href="#"> selling your home. </a>
            </p>
          </div>
          <p className="agent_list_p">
            Agents listed in the directory under ‘Find an agent’ are not
            licensed with Zillow, Inc. or any of our affiliated entities.
          </p>
        </div>
        </div>
    </div>
  )
}

export default Sell_sec1