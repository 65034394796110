import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query'
import { getCityProperties } from '../../api/api';
import Maps from '../common/maps'
import Loading from '../common/loading';
export default function Property() {
  const { city } = useParams()
 
  const queryClient = useQueryClient();
  const { isLoding, isSuccess, data: cityPrperties } = useQuery(['cityProperties', city], () => getCityProperties(city))

  let publicUrl = process.env.PUBLIC_URL + '/'
  let imagealt = 'image'
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  if (!isSuccess) {
    return <Loading />
  }
  else {
    return (
      <div className="properties-area pd-top-92">

        <div className="container">
          <div className="section-title">
            <h2 className="title">{city}</h2>
          </div>
          <div className="row">
            {isSuccess ?
              <>{cityPrperties.length !== 0 ?
                cityPrperties.map((item, i) =>
                  <div key={i} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className="single-feature">
                      <div className="thumb">
                        <img src={item.photos?.[0]?.url} alt="img" />
                      </div>
                      <div className="details">
                        <p className="author"><i className="fa fa-user" /> {item.association.name}</p>
                        <h6 className="title readeal-top"><Link to={`/property-details/${item.mlsId}`}>{item.address.crossStreet}</Link></h6>
                        <h6 className="price"><span>$ {(item.listPrice).toLocaleString()}</span></h6>
                        <ul className="info-list">
                          <li><i className="fa fa-bed" /> <span >0{item.property.bedrooms} Bed </span></li>
                          <li><i className="fa fa-bath" /> <span >0{item.property.bathsFull} Bath </span></li>

                          <li><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt} /> {item.property.area} sq.</li>
                        </ul>
                        <ul className="contact-list">
                          <li><a className="phone" href={"tel:+47"}><i className="fa fa-phone" /></a></li>
                          <li><a className="message" href="sms:+911234567890"><img src={publicUrl + "/assets/img/icons/8.png"} alt="img" /></a></li>
                          <li className="readeal-top"><Link className="btn btn-yellow" to={`/property-details/${item.mlsId}`} >View Details</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
                :
                <div className="row justify-content-center">
                  <h5 className='text-center'>&nbsp;&nbsp;&nbsp;No property available for this city</h5>
                </div>
              }
              </> :
              <></>
            }

          </div>
          <div className='row p-2'>
            <div className="col-md-12 col-sm-12">
              {isSuccess ? <Maps properties={cityPrperties} /> : <></>}
            </div>
          </div>
        </div>
      </div>

    )
  }
}
