import React from "react";
import SearchIcon from "@mui/icons-material/Search";
const Sell_sec4 = () => {
  return (
    <div className="container">
      <div className="row sell_1row justify-content-center">
        <h3>Go-to resources for a successful sale</h3>
        <div className="img_sec4">
          <p className="para1">Explore your home's value</p>
          <p className="para2">
            Enter your address to get your free Zestimate.
          </p>
          <form className="form_sec4">
            <div className="input-group input_sec4">
              <input
                type="email"
                className="form-control"
                id="inlineFormInputGroupUsername2"
                placeholder="Enter your email"
              />
              <SearchIcon className="icon_serch_" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Sell_sec4;
