export function convertToEmbedUrl(fileUrl) {
  const regex = /\/d\/([\w-]+)\//;
  const match = fileUrl.match(regex);

  if (match && match[1]) {
    const presentationId = match[1];
    const embedUrl = `https://docs.google.com/presentation/d/${presentationId}/embed`;

    return embedUrl;
  }
  return null;
}
