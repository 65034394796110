import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import sectiondata from "../../data/sections.json";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import SearchIcon from '@mui/icons-material/Search';

export default function Banner() {
  const [location, setLocation] = useState("");
  const [dropdownOpenRoom, setDropdownopenRoom] = useState(false);
  const [dropdownOpenPrice, setDropdownopenPrice] = useState(false);
  const [dropdownOpenStyle, setDropdownopenStyle] = useState(false);
  const [minBeds, setMinBeds] = useState("0");
  const [maxBeds, setMaxBeds] = useState("Any");
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("Any");
  const [propertyStyle, setPropertyStyle] = useState("Select Home Style");

  const inlineStyle = {
    backgroundImage: "url(/assets/img/banner/home_1.jpg)",
  };

  const handleSubmit = () => {
    console.log("Property Style", propertyStyle);
  };
  return (
    <div className="banner-area jarallax" style={inlineStyle}>
      <div className="container">
        <div className="banner-inner-wrap">
          <div className="row w-100 justify-content-center">
            <div className="find-serch">
              <h2>Find it. Tour it. Own it.</h2>
            </div>
            <div className="col-12 col-md-7 mx-auto">
              <div className="banner-single-input">
                <input type="text" placeholder="Enter an address, neighborhood, city, or ZIP code" />
                <Link className="search-icon-input" to="/search-list" onClick={handleSubmit}>
                  <i class="fa fa-search"></i>
                </Link>
              </div>

            </div>
          </div>
          {/* <div className="row">
          <div className="col-12">
            <div className="banner-inner">
              <h5 className="sub-title">{ data.subtitle }</h5>
              <h1 className="title">{ data.title1 } <br /> { data.title2 }</h1>
            </div>
          </div>
          <div className="col-12">
            <div className="banner-search-wrap">
              <ul className="nav nav-tabs rld-banner-tab">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#tabs_1">For Buy</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tabs_2">For Rent</a>
                </li>
              </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tabs_1">
                <div className="rld-main-search">
                  <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6">
                      <div className="rld-single-input left-icon">
                        <input type="text" placeholder="Entry Landmark Location" />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6">
                      <div className="rld-single-select">
                     
                     <Dropdown  isOpen={dropdownOpenRoom} toggle={()=> setDropdownopenRoom(!dropdownOpenRoom)}>
                        <DropdownToggle  color="primary" >
                          {minBeds === '0' && maxBeds === '10' ? 
                          <> Select Rooms </>
                          : 
                          <>{minBeds} Rooms - {maxBeds} Rooms</>
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem header>
                            <div className="">
                              <div className="rld-single-select">
                                <select className="select single-select"  value={minBeds} onChange={(e) => setMinBeds(e.target.value)} >
                                  <option value={'0'} selected>from</option>
                                  <option value={'1'}>1</option>
                                  <option value={'2'}>2</option>
                                  <option value={'3'}>3</option>
                                  <option value={'4'}>4</option>
                                  <option value={'5'}>5</option>
                                  <option value={'6'}>6</option>
                                  <option value={'7'}>7</option>
                                  <option value={'8'}>8</option>
                                </select>
                              </div>
                            </div>
                          </DropdownItem>
                          <DropdownItem header>
                            <div className="">
                              <div className="rld-single-select">
                                <select className="select single-select" value={maxBeds} onChange={(e)=> setMaxBeds(e.target.value)}>
                                  <option value={'1'}>1</option>
                                  <option value={'3'}>2</option>
                                  <option value={'3'}>3</option>
                                  <option value={'4'}>4</option>
                                  <option value={'5'}>5</option>
                                  <option value={'6'}>6</option>
                                  <option value={'7'}>7</option>
                                  <option value={'8'}>8</option>
                                  <option value={'9'}>9</option>
                                  <option value={'10'}>10</option>
                                  <option value={'Any'}>Any</option>
                                </select>
                              </div>
                              </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      </div>
                        </div>
                      <div className="col-xl-2 col-lg-6 col-md-6">
                        <div className="rld-single-select">
                       
                        <Dropdown  isOpen={dropdownOpenPrice} toggle={()=> setDropdownopenPrice(!dropdownOpenPrice)}>
                        <DropdownToggle  color="primary" >
                          {minPrice === '0' && maxPrice === 'Any' ? 
                          <> Select Price </>
                          : 
                          <>${minPrice} - ${maxPrice}</>
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem header>
                            <div className="">
                              <div className="rld-single-select">
                                <select  className="select single-select" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} >
                                  <option value={'0k'} selected>from</option>
                                  <option value={'0k'} >0k</option>
                                  <option value={'100k'}>100k</option>
                                  <option value={'200k'}>200k</option>
                                  <option value={'300k'}>300k</option>
                                  <option value={'400k'}>400k</option>
                                  <option value={'500k'}>500k</option>
                                  <option value={'600k'}>600k</option>
                                  <option value={'700k'}>700k</option>
                                  <option value={'800k'}>800k</option>
                                </select>
                              </div>
                            </div>
                          </DropdownItem>
                          <DropdownItem header>
                            <div className="">
                              <div className="rld-single-select">
                                <select className="select single-select" value={maxPrice} onChange={(e)=> setMaxPrice(e.target.value)}>
                                  <option value={'0'}>to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                  <option value={'200k'}>200k</option>
                                  <option value={'300k'}>300k</option>
                                  <option value={'400k'}>400k</option>
                                  <option value={'500k'}>500k</option>
                                  <option value={'600k'}>600k</option>
                                  <option value={'700k'}>700k</option>
                                  <option value={'800k'}>800k</option>
                                  <option value={'900k'}>900k</option>
                                  <option value={'1000k'}>1000k</option>
                                  <option value={'Any'}>Any</option>
                                </select>
                              </div>
                              </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      </div>
                        </div>
                        
                      <div className="col-xl-2 col-lg-4 col-md-4">
                        <div className="rld-single-select">
                      
                        <Dropdown  isOpen={dropdownOpenStyle} toggle={()=> setDropdownopenStyle(!dropdownOpenStyle)}>
                        <DropdownToggle  color="primary" >
                          {propertyStyle}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setPropertyStyle('Select Home Style')}>Select Home Style</DropdownItem>
                          <DropdownItem divider></DropdownItem>
                          <DropdownItem onClick={() => setPropertyStyle('Ranch')}>Ranch</DropdownItem>
                          <DropdownItem onClick={() => setPropertyStyle('Colonial')}>Colonial</DropdownItem>
                          <DropdownItem onClick={() => setPropertyStyle('Mediterranean')}>Mediterranean</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      </div>
                        
                      </div>
                      <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                          <Link className="btn btn-yellow" to={`/search-list/${minPrice}/${maxPrice}/${minBeds}/${maxBeds}/${propertyStyle}`} onClick={handleSubmit}>SEARCH NOW</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}
