import React from 'react'
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";

const Sell_sec3 = () => {
  return (
    <div className="container">
    <div className="row sell_1row">
     <div className="col-md-8">
       <div className="sell_1col">
         <h2>Sell your home yourself</h2>
         <p>
         Deciding to sell your home yourself is referred to as for-sale-by-owner (FSBO). The FSBO process is similar to traditional selling, but without the help of a real estate agent. In this case, you’re responsible for the home prep, marketing, showings, and negotiations.
         </p>
         <Button variant="contained" className="mt-5 ">
           Post your home for sale
         </Button>
         <br />
         <a href="#">Learn more about FSBO</a>
       </div>
     </div>
     <div className="col-md-4">
       <img src="/home//assets/img//Sell_My_HomeV1102.png" alt="" />
     </div>
   </div>
   <div className="row tab_scr">
     <div className="col-md-4 col-12">
       <h5 className="font-weight-bold pb-3">Why sell FSBO</h5>
       <ul className="correct_ul">
         <li>
           <CheckIcon className="check_icon" />
         </li>
         <li>
           <p>Avoid paying a listing agent commission</p>
         </li>
       </ul>
       <ul className="correct_ul">
         <li>
           <CheckIcon className="check_icon" />
         </li>
         <li>
           <p>Advertise your listing for free on Stickball</p>
         </li>
       </ul>
       <ul className="correct_ul">
         <li>
           <CheckIcon className="check_icon" />
         </li>
         <li>
           <p>Flexibility and control from start to finish</p>
         </li>
       </ul>
     </div>
     <div className="col-md-6 col-12">
       <h5 className="font-weight-bold pb-3">How to sell FSBO</h5>
       <p className="para_sell">
       When selling yourself, start with home prep, staging, and hiring a professional photographer. Once your marketing materials are ready, research comparable homes to help price your home. Then, create a listing on Zillow. You’ll likely host home showings or open houses. Then select an offer, negotiate, accept and close.
       </p>
     </div>
  
   </div>
   <hr className='line_yellow'/>
   </div>
  )
}

export default Sell_sec3