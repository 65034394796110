import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  Modal,
  Dialog,
  DialogContent
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio";
import TwentyFourMpSharpIcon from "@mui/icons-material/TwentyFourMpSharp";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { useQuery, useQueryClient } from "react-query";
import { fetchProperties } from "../../api/api";
import toast from 'react-hot-toast'

import { setCart, removeCart } from '../../redux-features/cart/cartSlice'
import { useDispatch, useSelector } from "react-redux";
import logoIcon from '../assets/logo.svg'
const style = {
  display: 'flex',
  justifyContent: 'center',
  // position: "absolute",
  // top: "51%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 90,
  bgcolor: "background.paper",
  // boxShadow: 24,
  // p: 0,
};

// class Service extends Component {
export default function Service() {
  const dispatch = useDispatch()
  const [openm, setOpenm] = useState(false);
  const [property, setProperty] = useState({
    photos: []
  })

  const cart = useSelector(state => state.cart.products)
  const { isLoding, isSuccess, data: pData, } = useQuery(["all-properties", 1, 10], () => fetchProperties(1, 10));
  const handleOpenm = () => {
    setOpenm(true);
  };
  const handleClosem = () => {
    setOpenm(false);
  };
  function format(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  let data = sectiondata.services;
  return (
    isSuccess &&
    <>
      <div className="service-area h1-service-slider-area">
        <div className="container">
          <h5>Homes For You</h5>
          <hr />
          <div className="service-slider d-flex">
            {pData?.data?.map((item, i) => (
              <div key={item._id} className="item">
                <Card sx={{ maxWidth: 345 }} className="mr-3 service_hover">
                  <CardMedia
                    component="img"
                    height="140"
                    image={item?.photos?.[0]?.url}
                    onClick={() => {
                      setProperty(item)
                      handleOpenm()
                    }}
                    alt="image"
                  />

                  <Chip label="Garage entrance" className="chip-front-page" />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      ${format(item?.price)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item?.bedrooms} bds | {item?.bathrooms} ba | {format(item?.livingArea?.toString())} sqft - {item?.homeStatus === "FOR_SALE" ? "House for sale" : 'Rental House'}
                      {item?.address?.streetAddress}, {item?.address?.city}, {item?.address?.state}, {item?.address?.zipcode}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Dialog
        open={openm}
        fullScreen
        onClose={handleClosem}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box >
          <Button onClick={handleClosem} sx={{ position: 'absolute', right: '10px', top: '5px', zIndex: 1000 }}>
            <CloseIcon />
          </Button>
          <div className="row">
            <div className="col-md-5 col-lg-7 col-12 p-2">
              <img
                src={property?.photos?.[0]?.url}
                alt="image"
                className="w-100"
              />
              <div className="row">
                {
                  property?.photos?.map((photo, index) => {
                    return (
                      index !== 0 &&
                      <div key={photo?.url} className="col-md-6 pr-0">
                        <img
                          src={photo?.url}
                          alt="image"
                          className="w-100 py-1"
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-md-7 col-lg-5 col-12 p-2">
              <nav className="navbar nav_modal">
                <Link to="/">
                  <img
                    src={logoIcon}
                    alt="logo"
                  />
                </Link>
                {/* <div className="float-right link_nav">
                          <a href="#">Save</a>
                          <a href="#">Share</a>
                          <a href="#">
                            <MoreHorizIcon
                              style={{ verticalAlign: "bottom" }}
                            />
                            More
                          </a>
                        </div> */}
              </nav>
              <div className="px-3">
                <p style={{ color: "#000" }}>
                  <span className="dollor">${format(property?.price)}</span>
                  <span className="fw_bold">{property?.bedrooms}</span> bd |
                  <span className="fw_bold">{format(property?.livingArea)}</span> sqft {property?.address?.streetAddress}, {property?.address?.city}, {property?.address?.state} {property?.address?.zipcode}
                </p>
                <p style={{ color: "#000" }}>
                  <span className="for_sale">
                    <FiberManualRecordIcon className="for_sale_icon" />
                    {property?.homeStatus === "FOR_SALE" ? "For Sale" : "For Rent"}
                  </span>
                  | Zestimate®: |
                  <span className="for_sale">${format(property?.price)}</span>
                </p>
                <p style={{ color: "#000" }}>
                  <span className="fw_bold">Est. payment : </span>
                  ${format(property?.price / 12)}/mo
                </p>
                <Stack spacing={3} direction="row" className="two_btn">
                  <Button variant="contained">Request a Tour</Button>
                  <Button
                    onClick={() => {
                      let foundInCart = cart?.find(obj => obj._id === property?._id)
                      if (foundInCart) {
                        dispatch(removeCart(property?._id))
                        toast.success("Removed from Cart")
                        return
                      }
                      dispatch(setCart(property))
                      toast.success("Added to Cart")
                    }}
                    sx={{ backgroundColor: cart?.find(obj => obj._id === property?._id) ? "rgb(251, 120, 92)" : '#1976d2' }}
                    variant="contained">{cart?.find(obj => obj._id === property?._id) ? "Remove from Cart" : "Add to Cart"}</Button>
                </Stack>
                <div className="my-3">
                  <ul className="over-view">
                    <li><ListAltIcon className="text-primary" /></li>
                    <li><p>Singlefamily</p></li>
                  </ul>
                  <ul className="over-view">
                    <li><CalendarMonthIcon className="text-primary" /></li>
                    <li><p>Built in {property?.yearBuilt}</p></li>
                  </ul>
                  <ul className="over-view">
                    <li><ThermostatIcon className="text-primary" /></li>
                    <li><p>Forced air, gas</p></li>
                  </ul>
                  <ul className="over-view">
                    <li><AcUnitIcon className="text-primary" /></li>
                    <li><p>Central</p></li>
                  </ul>
                  <ul className="over-view">
                    <li><TwentyFourMpSharpIcon className="text-primary" /></li>
                    <li><p>Garage - detached</p></li>
                  </ul>
                  <ul className="over-view">
                    <li><ImageAspectRatioIcon className="text-primary" /></li>
                    <li><p>{format(property?.livingArea)} sqft</p></li>
                  </ul>
                  <ul className="over-view">
                    <li><SquareFootIcon className="text-primary" /></li>
                    <li><p>${format((property?.price / property?.livingArea).toFixed(0))} price/sqft</p></li>
                  </ul>
                  <h4>Overview</h4>
                  <p className="overview-days"> <span> {property?.daysOnZillow} days </span> on Stickball</p>
                  <h4>Facts and features</h4>
                  <ul className="pl-4">
                    <li>Bedrooms: {property?.bedrooms}</li>
                    <li>Bathrooms: {property?.bathrooms}</li>
                  </ul>
                  <h4>Location</h4>
                  <ul className="pl-4">
                    <li>Street: {property?.address?.streetAddress} </li>
                    <li>City: {property?.address?.city} </li>
                    <li>State: {property?.address?.state} </li>
                    <li>Zip Code: {property?.address?.zipcode} </li>
                  </ul>
                  <h4>Estimated market value</h4>
                  <div className="text-center">
                    <p className="zestimate-para">Zestimate®</p>
                    <h4>${format(property?.price)}</h4>
                    <p className="estimate-sale">Estimated sales range: ${format(property?.price - 3000)} - ${format(property?.price + 3000)} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Dialog>
    </>
  );
}

// import React, { Component } from 'react';
// import sectiondata from '../../data/sections.json';
// import parse from 'html-react-parser';
// import { Link } from 'react-router-dom';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';

// class Service extends Component {

//     render() {

//         let publicUrl = process.env.PUBLIC_URL+'/'
//         let imagealt = 'image'
//         let data = sectiondata.services

//         return <div className="service-area h1-service-slider-area">
//         <div className="container">
//           <h5>Homes For You</h5>
//           <hr />
//           <div className="service-slider">
//             { data.items.map( ( item, i )  =>
//                 <div key={ i } className="item">
//                  <Card sx={{ maxWidth: 345 }} className="mr-3 service_hover">
//       <CardMedia
//         component="img"
//         height="140"
//         image="/home//assets/img/woodland.jpg"
//         alt="image"
//       />

//       <Chip label="Garage entrance" className='chip_card' />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//           $135,000
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//         3 bds | 2 ba | 1,460 sqft | House for sale
// 2428 W Lakeshore Dr #74020, Cleveland, OK 74020
// COLDWELL BANKER SELECT, Beck Edens
//         </Typography>
//       </CardContent>
//     </Card>
//                 </div>
//              ) }
//           </div>
//         </div>
//       </div>

// old

// return <div className="service-area h1-service-slider-area">
//     <div className="container">
//       <div className="service-slider">
//         { data.items.map( ( item, i )  =>
//             <div key={ i } className="item">
//               <div className="single-service text-center">
//                 <div className="thumb double-img">
//                   <img src={ publicUrl+item.icon } alt="icons" />
//                 </div>
//                 <div className="details readeal-top">
//                   <h4><Link to="/">{ item.title }</Link></h4>
//                   <Link className="readmore-btn" to="/">{ item.btntxt } <i className="la la-arrow-right" /></Link>
//                 </div>
//               </div>
//             </div>
//          ) }
//       </div>
//     </div>
//   </div>

//         }
// }

// export default Service
