import React, { Component } from "react";
// import { styled } from "@mui/system";
import sectiondata from "../../data/sections.json";
import { Link } from 'react-router-dom'

import {
  // Card,
  // CardContent,
  // CardMedia,
  // Button,
  // Typography,
  Grid,
  Box,
} from "@mui/material";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";

const data = [
  {
    url: "https://wp-tid.zillowstatic.com/bedrock/app/uploads/sites/5/2022/07/Buy_a_home.webp",
    alt: "",
    title: "Buy a home",
    description:
      "Find your place with an immersive photo experience and the most listings, including things you won't find anywhere else.",
    btnText: "Browse homes",
    to: '/search-list?type=buy'
  },
  {
    url: "https://wp-tid.zillowstatic.com/bedrock/app/uploads/sites/5/2022/07/Sell_a_home.webp",
    alt: "",
    title: "Sell a home",
    description:
      "No matter what path you take to sell your home, we can help you navigate a successful sale.",
    btnText: "See your options",
    to: '/sell'
  },
  {
    url: "https://wp-tid.zillowstatic.com/bedrock/app/uploads/sites/5/2022/08/Rent_a_home.webp",
    alt: "",
    title: "Rent a home",
    description:
      "We're creating a seamless online experience - from shopping on the largest rental network to applying, to paying rent.",
    btnText: "Find rentals",
    to: '/search-list?type=rent'
  },
];

const ServiceCard = () => {
  return (
    <>
      <div className="container-fluid py-5 mt-5" style={{ backgroundColor: '#f9f9fb' }}>
        <Box sx={{ width: "90%", margin: "50px auto" }}>
          <Grid container spacing={4}>
            {data?.map((item) => (
              <Grid item xs={12} sm={12} md={4} lg={4} textAlign="center">
                <div style={{ display: "grid", justifyContent: "space-around" }}>
                  <Card style={{ padding: 10 }} className="card-hover">
                    <CardImg top width="100%" src={item.url} alt={item.alt} />
                    <CardBody>
                      <CardTitle>
                        {" "}
                        <h4>{item.title}</h4>
                      </CardTitle>
                      {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                      <CardText>{item.description}</CardText>
                      <Link to={item.to}>
                        <button type="button"
                          className="btn_service_card"
                          style={{ margin: "20px 0px" }}
                        >{item.btnText}</button>
                      </Link>

                      {/* <button
                      className="btn-outline btn-yellow"
                      style={{ margin: "20px 0px" }}
                    >
                      {item.btnText}
                    </button> */}
                    </CardBody>
                  </Card>
                </div>
                {/* <Card
                hover
                sx={{
                  padding: "24px",
                  maxWidth: "100%",
                  minWidth: 300,
                  maxWidth: 600,
                  gridTemplateColumns: "repeat(3, 1fr)",
                }}
              >
                <CardMedia component="img" image={item.url} alt="" />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h4"
                    fontSize={26}
                    fontWeight={600}
                    component="div"
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>
                <button
                  className="btn btn-yellow"
                  style={{ margin: "24px 0px 30px 0px", padding: "9px, 16px" }}
                  variant="outlined"
                >
                  {item.btnText}
                </button>
              </Card> */}
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default ServiceCard;