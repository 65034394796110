import React, { Component } from "react";
import { Link } from "react-router-dom";
import footerdata from "../../data/footerdata.json";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import logoIcon from '../assets/logo.svg'
import artImage from '../assets/footer-art.svg'

class Footer_v1 extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";
    const inlineStyle = {
      backgroundImage: "url(" + publicUrl + footerdata.footerbg + ")",
    };

    return (
      <footer className="footer-area">
        <div className="container">
          <div className="footer-top">
            <div className="row collpse_row">
              <div className="col-md-3 col-6">
                <a
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <span className="collapse_name"> Real Estate </span>{" "}
                  <KeyboardArrowDownIcon className="collpse_head" />
                </a>

                <div className="collapse" id="collapseExample">
                  <div className="card card-body">
                    <ul>
                      <li>
                        <a href="#">Browse all homes</a>
                      </li>
                      <li>
                        <a href="#">Albuquerque real estate</a>
                      </li>
                      <li>
                        <a href="#">Atlanta real estate</a>
                      </li>
                      <li>
                        <a href="#">Austin real estate</a>
                      </li>
                      <li>
                        <a href="#">Baltimore real estate</a>
                      </li>
                      <li>
                        <a href="#">Boston real estate</a>
                      </li>
                      <li>
                        <a href="#">Calgary real estate</a>
                      </li>
                      <li>
                        <a href="#">Charlotte real estate</a>
                      </li>
                      <li>
                        <a href="#">Chicago real estate</a>
                      </li>
                      <li>
                        <a href="#">Colorado Springs real estate</a>
                      </li>
                      <li>
                        <a href="#">Columbus real estate</a>
                      </li>
                      <li>
                        <a href="#">Dallas real estate</a>
                      </li>
                      <li>
                        <a href="#">Edmonton real estate</a>
                      </li>
                      <li>
                        <a href="#">Washington DC real estate</a>
                      </li>
                      <li>
                        <a href="#">Wichita real estate</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <a
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseExample2"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <span className="collapse_name"> Rentals </span>{" "}
                  <KeyboardArrowDownIcon className="collpse_head" />
                </a>
                <div className="collapse" id="collapseExample2">
                  <div className="card card-body">
                    <ul>
                      <li>
                        <a href="#">Browse all homes</a>
                      </li>
                      <li>
                        <a href="#">Albuquerque real estate</a>
                      </li>
                      <li>
                        <a href="#">Atlanta real estate</a>
                      </li>
                      <li>
                        <a href="#">Austin real estate</a>
                      </li>
                      <li>
                        <a href="#">Baltimore real estate</a>
                      </li>
                      <li>
                        <a href="#">Boston real estate</a>
                      </li>
                      <li>
                        <a href="#">Calgary real estate</a>
                      </li>
                      <li>
                        <a href="#">Charlotte real estate</a>
                      </li>
                      <li>
                        <a href="#">Chicago real estate</a>
                      </li>
                      <li>
                        <a href="#">Colorado Springs real estate</a>
                      </li>
                      <li>
                        <a href="#">Columbus real estate</a>
                      </li>
                      <li>
                        <a href="#">Dallas real estate</a>
                      </li>
                      <li>
                        <a href="#">Edmonton real estate</a>
                      </li>
                      <li>
                        <a href="#">Washington DC real estate</a>
                      </li>
                      <li>
                        <a href="#">Wichita real estate</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <a
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseExample3"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <span className="collapse_name"> Mortgage Rates </span>{" "}
                  <KeyboardArrowDownIcon className="collpse_head" />
                </a>
                <div className="collapse" id="collapseExample3">
                  <div className="card card-body">
                    <ul>
                      <li>
                        <a href="#">Browse all homes</a>
                      </li>
                      <li>
                        <a href="#">Albuquerque real estate</a>
                      </li>
                      <li>
                        <a href="#">Atlanta real estate</a>
                      </li>
                      <li>
                        <a href="#">Austin real estate</a>
                      </li>
                      <li>
                        <a href="#">Baltimore real estate</a>
                      </li>
                      <li>
                        <a href="#">Boston real estate</a>
                      </li>
                      <li>
                        <a href="#">Calgary real estate</a>
                      </li>
                      <li>
                        <a href="#">Charlotte real estate</a>
                      </li>
                      <li>
                        <a href="#">Chicago real estate</a>
                      </li>
                      <li>
                        <a href="#">Colorado Springs real estate</a>
                      </li>
                      <li>
                        <a href="#">Columbus real estate</a>
                      </li>
                      <li>
                        <a href="#">Dallas real estate</a>
                      </li>
                      <li>
                        <a href="#">Edmonton real estate</a>
                      </li>
                      <li>
                        <a href="#">Washington DC real estate</a>
                      </li>
                      <li>
                        <a href="#">Wichita real estate</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <a
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseExample4"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <span className="collapse_name"> Browse Homes </span>{" "}
                  <KeyboardArrowDownIcon className="collpse_head" />
                </a>
                <div className="collapse" id="collapseExample4">
                  <div className="card card-body">
                    <ul>
                      <li>
                        <a href="#">Browse all homes</a>
                      </li>
                      <li>
                        <a href="#">Albuquerque real estate</a>
                      </li>
                      <li>
                        <a href="#">Atlanta real estate</a>
                      </li>
                      <li>
                        <a href="#">Austin real estate</a>
                      </li>
                      <li>
                        <a href="#">Baltimore real estate</a>
                      </li>
                      <li>
                        <a href="#">Boston real estate</a>
                      </li>
                      <li>
                        <a href="#">Calgary real estate</a>
                      </li>
                      <li>
                        <a href="#">Charlotte real estate</a>
                      </li>
                      <li>
                        <a href="#">Chicago real estate</a>
                      </li>
                      <li>
                        <a href="#">Colorado Springs real estate</a>
                      </li>
                      <li>
                        <a href="#">Columbus real estate</a>
                      </li>
                      <li>
                        <a href="#">Dallas real estate</a>
                      </li>
                      <li>
                        <a href="#">Edmonton real estate</a>
                      </li>
                      <li>
                        <a href="#">Washington DC real estate</a>
                      </li>
                      <li>
                        <a href="#">Wichita real estate</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <nav class="navbar navbar-footer my-4 py-3">
                <a href="#" className="pl-0">About</a>
                <a href="#">Zestimates</a>
                <a href="#">Research</a>
                <a href="#">Careers</a>
                <a href="#">Help</a>
                <a href="#">Advertise</a>
                <a href="#">Fair Housing Guide</a>
                <a href="#">Terms of use</a>
                <a href="#">Privacy Portal</a>
                <a href="#">Cookie Preference</a>
                <a href="#">Blog</a>
                <a href="#">AI</a>
                <a href="#">Mobile Apps</a>
                <a href="#">Trulia</a>
                <a href="#">StreetEasy</a>
                <a href="#">HotPads</a>
                <a href="#">Out East</a>
                <a href="#">ShowingTime</a>
                <div className="mt-4 pers-info-link">
                  <a href="#">Do Not Sell My Personal Information→</a>
                </div>
              </nav>
            </div>
            <div className="row justify-content-center">
              <p className="zillow_group">Zillow Group is committed to ensuring digital accessibility for individuals with disabilities. We are continuously working to improve the accessibility of our <br />
                web experience for everyone, and we welcome feedback and accommodation requests. If you wish to report an issue or seek an accommodation, please

                <br />
                <div className="zillow_group_link text-center">
                  <a href="#">let us know.</a>
                </div>
              </p>
            </div>
            <div className="row justify-content-center mt-3">
              <p className="zillow_group">Zillow, Inc. holds real estate brokerage <a href="#" className="footer_small_link">licenses</a> in multiple states. Zillow (Canada), Inc. holds real estate brokerage licenses in multiple provinces. <br />
                <div className="zillow_group_link text-center">
                  <a href="#">§ 442-H New York Standard Operating Procedures</a>
                </div>
                <div className="zillow_group_link text-center">
                  <a href="#">§ New York Fair Housing Notice</a>
                </div>
                <div className="zillow_group_link text-center">
                  <span>TREC: </span> <a href="#">Information about brokerage services, Consumer protection notice</a>
                  <br /> California DRE #1522444
                </div>
                <div className="zillow_group_link text-center mt-2">
                  <a href="#" style={{ fontSize: '14px' }}>Contact Stickball, Inc. Brokerage</a>
                </div>
              </p>

            </div>
            <div className="row justify-content-center text-center mt-3">
              <p className="zillow_group">
                For listings in Canada, the trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled by The Canadian Real Estate Association (CREA) and <br />
                identify real estate professionals who are members of CREA. The trademarks MLS®, Multiple Listing Service® and the associated logos are owned by CREA <br />
                and identify the quality of services provided by real estate professionals who are members of CREA. Used under license.
              </p>
            </div>
            <div className="row justify-content-center text-center mt-3">
              <div className="socil_lin_footer">
                <a className="footer-logo" href="#"><img src={logoIcon} alt={imgattr} /></a>
                <span>Follow US:</span> <a href="#"> <FacebookOutlinedIcon className="facebook_icon" /> </a>
                <a href="#"><InstagramIcon className="instigram_icon px-1" /> </a> <a href="#"><TwitterIcon className="twitter_icon" /></a><span>© 2019-2022 Sticball</span>
                <a href="#"><HouseSidingIcon className="house_icon" /></a>
              </div>
            </div>
          </div>
        </div>
        <img src={artImage} alt="img" className="mt-5" />
      </footer>
      //  <footer className="footer-area">
      //   <div className="container">
      //     <div className="footer-top">
      //       <div className="row">
      //         <div className="col-sm-4">
      //           <a className="footer-logo" href="#"><img src={ logoIcon} alt={ imgattr } /></a>
      //         </div>
      //         <div className="col-sm-8">
      //           {/* <div className="footer-social text-sm-right">
      //             <span>FOLLOW US</span>
      //             <ul className="social-icon">
      //              { footerdata.socialicon.map( ( item, i )=>
      //                 <li key={i}>
      //                   <a href={ item.url } target="_blank"><i className={ item.icon } /></a>
      //                 </li>
      //               ) }
      //             </ul>
      //           </div>   */}
      //         </div>
      //       </div>
      //     </div>
      //     <div className="footer-bottom">
      //       {/* <div className="row">
      //         <div className="col-lg-3 col-sm-6">
      //           <div className="widget widget_nav_menu">
      //             <h4 className="widget-title">{ footerdata.popularserces.title }</h4>
      //             <ul>
      //               { footerdata.popularserces.links.map( ( item, i )=>
      //                   <li className="readeal-top" key={ i }><Link to={ item.url } >{ item.title }</Link></li>
      //                ) }

      //             </ul>
      //           </div>
      //         </div>
      //         <div className="col-lg-3 col-sm-6">
      //           <div className="widget widget_nav_menu">
      //             <h4 className="widget-title">{ footerdata.Homepress.title }</h4>
      //             <ul>
      //               { footerdata.Homepress.links.map( ( item, i )=>
      //                  <li className="readeal-top" key={ i }><Link to={ item.url } >{ item.title }</Link></li>
      //               ) }
      //             </ul>
      //           </div>
      //         </div>
      //         <div className="col-lg-3 col-sm-6">
      //           <div className="widget widget_nav_menu">
      //            <h4 className="widget-title">{ footerdata.quicklink.title }</h4>
      //             <ul>
      //              { footerdata.quicklink.links.map( ( item, i )=>
      //                   <li className="readeal-top" key={ i }><Link to={ item.url } >{ item.title }</Link></li>
      //               ) }
      //             </ul>
      //           </div>
      //         </div>
      //         <div className="col-lg-3 col-sm-6">
      //           <form className="widget widget-subscribe" method="GET">
      //             <div className="rld-single-input">
      //               <input type="text" name="name" placeholder="Full Name" />
      //             </div>
      //             <div className="rld-single-input">
      //               <input type="text" name="email" placeholder="Your@email.com" />
      //             </div>
      //             <button className="btn btn-yellow w-100">Subscribe</button>
      //           </form>
      //         </div>
      //       </div> */}
      //     </div>
      //     <div className="copy-right text-center" dangerouslySetInnerHTML={{__html: footerdata.copyrighttext}}></div>
      //   </div>
      // </footer>
    );
  }
}

export default Footer_v1;
