import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: []
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart: (state, action) => {
            let temp = state?.products
            temp.push(action.payload)
            state.products = temp
        },
        removeCart: (state, action) => {
            state.products = state.products.filter(x => x?._id !== action.payload)
        },
        emptyCart: (state) => {
            state.products = []
        }
    },
})

export const { setCart, removeCart, emptyCart } = cartSlice.actions

export default cartSlice.reducer