import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Slider from '@mui/material/Slider';
import { Link } from 'react-router-dom';
export default function SearchListFilters() {
    const [dropdownOpenRoom, setDropdownopenRoom] = useState(false)
    const [dropdownOpenPrice, setDropdownopenPrice] = useState(false)
    const [dropdownOpenStyle, setDropdownopenStyle] = useState(false)
    const [minBeds, setMinBeds] = useState('0')
    const [maxBeds, setMaxBeds] = useState('Any')
    const [minPrice, setMinPrice] = useState('0')
    const [maxPrice, setMaxPrice] = useState('Any')
    const [minBath, setMinBath] = useState('0')
    const [maxBath, setMaxBath] = useState('Any')
    const [propertyStyle, setPropertyStyle] = useState('Select Home Style')
    const [valuePrice, setValuePrice] = useState([3, 10]);
    const [valueSize, setValueSize] = useState([4, 9])
    const minDistancePrice = 1;
    const minDistanceSize = 5;
    const handleChangePrice = (event, newValue, activeThumb) => {

        let num = newValue[0]
        console.log("Value IN Stirng", (num.toString()))
        setMinPrice((newValue[0].toString()))
        if (newValue[1] === 21) {
            setMaxPrice("Any")
        }
        else {
            setMaxPrice((newValue[1].toString()))
        }

        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValuePrice([Math.min(newValue[0], valuePrice[1] - minDistancePrice), valuePrice[1]]);
        } else {
            setValuePrice([valuePrice[0], Math.max(newValue[1], valuePrice[0] + minDistancePrice)]);
        }
    };
    const handleChangeSize = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValueSize([Math.min(newValue[0], valueSize[1] - minDistanceSize), valueSize[1]]);
        } else {
            setValueSize([valueSize[0], Math.max(newValue[1], valueSize[0] + minDistanceSize)]);
        }
    };
    function valueLabelFormatPrice(value) {
        const units = ['K', 'Any'];
        let unitIndex = 0;
        let scaledValue = value;

        if (scaledValue === 2100) {
            return `${"$"} ${units[1]}`;
        }
        return `${"$"}${scaledValue}${units[unitIndex]}`;
    }

    function calculateValuePrice(value) {
        return 100 * value;
    }
    function valueLabelFormatSize(value) {
        const units = ['sq.'];

        let unitIndex = 0;
        let scaledValue = value;
        if (scaledValue === 2100) {
            return `${"Any"} ${units[unitIndex]}`;
        }
        return `${scaledValue} ${units[unitIndex]}`;
    }

    function calculateValueSize(value) {
        return 100 * value;
    }
    const style = {
        color: "#616ded"
    }
    return (

        <div> <form className="widget widget-sidebar-search-wrap">
            <div className="widget-sidebar-search d-flex flex-column side-nav-gap">
                <div className="title">Any Price</div>
                <div className="widget-sidebar-item-wrap btn-area">
                    <button className="btn btn-yellow">For Buy</button>
                    <button className="btn btn-yellow float-right" >For Rent</button>
                </div>
                <div className="widget-sidebar-item-wrap rld-single-input left-icon">
                    <input type="text" placeholder="Entry Landmark Location" />
                </div>
                <div className="widget-sidebar-item-wrap rld-single-select">

                    <Dropdown isOpen={dropdownOpenStyle} toggle={() => setDropdownopenStyle(!dropdownOpenStyle)}>
                        <DropdownToggle className="select single-select mb-0" color="primary"  >
                            {propertyStyle}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => setPropertyStyle('Select Home Style')}>Select Home Style</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={() => setPropertyStyle('Ranch')}>Ranch</DropdownItem>
                            <DropdownItem onClick={() => setPropertyStyle('Colonial')}>Colonial</DropdownItem>
                            <DropdownItem onClick={() => setPropertyStyle('Mediterranean')}>Mediterranean</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                </div>
                <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                    <div className="title">Any Price</div>
                    <div className="price">
                        <Slider
                            style={style}
                            getAriaLabel={() => 'Minimum distance'}
                            min={0}
                            step={1}
                            max={21}
                            value={valuePrice}
                            scale={calculateValuePrice}
                            onChange={handleChangePrice}
                            getAriaValueText={valueLabelFormatPrice}
                            valueLabelFormat={valueLabelFormatPrice}
                            valueLabelDisplay="auto"
                            disableSwap
                        // getAriaValueText={valuetext}
                        />
                    </div>

                </div>
                <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                    <div className="title">Size</div>
                    <div className="price">
                        <Slider style={style}
                            getAriaLabel={() => 'Minimum distance'}
                            min={0}
                            step={1}
                            max={21}
                            value={valueSize}
                            scale={calculateValueSize}
                            onChange={handleChangeSize}
                            getAriaValueText={valueLabelFormatSize}
                            valueLabelFormat={valueLabelFormatSize}
                            valueLabelDisplay="auto"
                            disableSwap
                        // getAriaValueText={valuetext}
                        />
                    </div>

                </div>
                <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                    <div className="title d-inline-block float-left mb-3 pt-2">Bedroom</div>
                    <div className="widget-sidebar-item-wrap rld-single-select">
                        <Dropdown isOpen={dropdownOpenRoom} toggle={() => setDropdownopenRoom(!dropdownOpenRoom)}>
                            <DropdownToggle className='mb-0 single-select' color="primary" >
                                {minBeds === '0' && maxBeds === '10' ?
                                    <> Select Rooms </>
                                    :
                                    <>{minBeds} Rooms - {maxBeds} Rooms</>
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>
                                    <div className="">
                                        <div className="rld-single-select">
                                            <select  className="select single-select mb-0"value={minBeds} onChange={(e) => setMinBeds(e.target.value)} >
                                                <option value={'0'} selected>from</option>
                                                <option value={'1'}>1</option>
                                                <option value={'2'}>2</option>
                                                <option value={'3'}>3</option>
                                                <option value={'4'}>4</option>
                                                <option value={'5'}>5</option>
                                                <option value={'6'}>6</option>
                                                <option value={'7'}>7</option>
                                                <option value={'8'}>8</option>
                                            </select>
                                        </div>
                                    </div>
                                </DropdownItem>
                                <DropdownItem header>
                                    <div className="">
                                        <div className="rld-single-select">
                                            <select className="select single-select mb-0" value={maxBeds} onChange={(e) => setMaxBeds(e.target.value)}>
                                                <option value={'0'}>to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                                <option value={'1'}>1</option>
                                                <option value={'3'}>2</option>
                                                <option value={'3'}>3</option>
                                                <option value={'4'}>4</option>
                                                <option value={'5'}>5</option>
                                                <option value={'6'}>6</option>
                                                <option value={'7'}>7</option>
                                                <option value={'8'}>8</option>
                                                <option value={'9'}>9</option>
                                                <option value={'10'}>10</option>
                                                <option value={'Any'}>Any</option>
                                            </select>
                                        </div>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                    <div className="title d-inline-block float-left mb-3 pt-2">Bathroom</div>
                    <div className="widget-sidebar-item-wrap rld-single-select">
                        <Dropdown isOpen={dropdownOpenPrice} toggle={() => setDropdownopenPrice(!dropdownOpenPrice)}>
                            <DropdownToggle className="select single-select mb-0" color="primary" >
                                {minBath === '0' && maxBath === 'Any' ?
                                    <> Select Bath </>
                                    :
                                    <>{minBath} Bath - {maxBath} Bath</>
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>
                                    <div className="">
                                        <div className="rld-single-select">
                                            <select className="select single-select mb-0"  value={minBath} onChange={(e) => setMinBath(e.target.value)} >
                                                <option value={'0'} selected>from</option>
                                                <option value={'1'}>1</option>
                                                <option value={'2'}>2</option>
                                                <option value={'3'}>3</option>
                                                <option value={'4'}>4</option>
                                                <option value={'5'}>5</option>
                                                <option value={'6'}>6</option>
                                                <option value={'7'}>7</option>
                                                <option value={'8'}>8</option>
                                            </select>
                                        </div>
                                    </div>
                                </DropdownItem>
                                <DropdownItem header>
                                    <div className="">
                                        <div className="rld-single-select">
                                            <select className="select single-select mb-0" value={maxBath} onChange={(e) => setMaxBath(e.target.value)}>
                                                <option value={'0'}>to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                                <option value={'1'}>1</option>
                                                <option value={'3'}>2</option>
                                                <option value={'3'}>3</option>
                                                <option value={'4'}>4</option>
                                                <option value={'5'}>5</option>
                                                <option value={'6'}>6</option>
                                                <option value={'7'}>7</option>
                                                <option value={'8'}>8</option>
                                                <option value={'9'}>9</option>
                                                <option value={'10'}>10</option>
                                                <option value={'Any'}>Any</option>
                                            </select>
                                        </div>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>

            </div>
            <div className="btn-wrap text-center">
                <Link className="btn btn-yellow" to={`/search-list/${minPrice}/${maxPrice}/${minBeds}/${maxBeds}/${propertyStyle}?type=buy`} >SEARCH</Link>            </div>
        </form></div>
    )
}
