import React, { useState } from 'react'
import Navbar from './global-components/navbar';
import { useSelector, useDispatch } from 'react-redux';
import { emptyCart } from '../redux-features/cart/cartSlice'
import Card from './section-components/card/Card'
import toast from 'react-hot-toast'
import 'bootstrap/dist/css/bootstrap.css'
import { checkout } from '../api/index'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Grid,
    TextField,
    Card as CuCard,
    CardContent,
    Typography,
    Button
} from '@mui/material';
export default function Checkout() {
    const dispatch = useDispatch()
    const [message, setMessage] = useState("")
    const cart = useSelector(state => state.cart.products)

    const formik = useFormik({
        initialValues: {
            name: '',
            cardNumber: null,
            cvv: null,
        },
        validationSchema: Yup.object({
            name: Yup.string().max(50, "Name should be of less than 50 charactors").required("Name is required").matches(/^[A-z\" "]*$/, 'Please enter a valid Name').min(3, "Name must be at least 3 characters"),
            cardNumber: Yup.number().required("Card Numbher is required").test('len', 'Must be exactly 16 digits', val => val?.toString().length === 16).typeError("Please enter a valid Card Number"),
            // cardNumber: Yup.number().min(16).max(16).required(),
            cvv: Yup.number().required("CVV is required").test('len', 'Must be exactly 3 digits', val => val?.toString().length === 3).typeError("Please enter a valid CVV"),
        }),
        onSubmit: async (values, helpers) => {
            setMessage("")
            let total = 0
            try {
                toast.loading("Please wait your order is in process...")
                cart.map(obj => {
                    total += obj.price
                })
                const res = await checkout({
                    cardnumber: values.cardNumber,
                    cvv: values.cvv,
                    totalPrice: total,
                })
                console.log("Res: ", res)
                toast.dismiss()
                toast.success("Order Placed Successfullt")
                dispatch(emptyCart())
                window.history.go(-1)
            } catch (err) {
                setMessage(err?.response?.data?.message)
                toast.dismiss()
                toast.error(err?.response?.data?.message)
            }
        },
    });

    function format(x) {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function totalAmount() {
        let total = 0
        cart.map((obj) => {
            total += obj.price
        })
        return format(total)
    }

    return (
        <>
            <Navbar />
            <Grid className="container" sx={{ mt: 12 }}>
                <section>
                    <div className="row">
                        <div className="col-md-7 mb-4">
                            <CuCard sx={{ p: 2 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    Credit Card Details
                                </Typography>
                                <CardContent>
                                    <p className="text-danger">{message}</p>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row mb-4">
                                            <TextField
                                                sx={{ mt: 3 }}
                                                error={Boolean(formik.touched.name && formik.errors.name)}
                                                fullWidth
                                                helperText={formik.touched.name && formik.errors.name}
                                                placeholder="Your Name (Verify on credit card)"
                                                variant="outlined"
                                                name="name"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.name}
                                            />
                                            <TextField
                                                sx={{ mt: 3 }}
                                                error={Boolean(formik.touched.cardNumber && formik.errors.cardNumber)}
                                                fullWidth
                                                helperText={formik.touched.cardNumber && formik.errors.cardNumber}
                                                placeholder="Credit Card Number"
                                                name="cardNumber"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.cardNumber}
                                            />
                                            <TextField
                                                sx={{ mt: 3 }}
                                                error={Boolean(formik.touched.cvv && formik.errors.cvv)}
                                                fullWidth
                                                helperText={formik.touched.cvv && formik.errors.cvv}
                                                placeholder="CVV (Three digit code on card)"
                                                name="cvv"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.cvv}
                                            />
                                        </div>
                                        <Button fullWidth variant="contained" type="submit" style={{ fontWeight: 600, mt: 3 }}>
                                            Place Order
                                        </Button>
                                    </form>
                                </CardContent>
                            </CuCard>
                        </div>

                        <div className="col-md-5 mb-4">
                            <CuCard sx={{ p: 2 }}>
                                <Typography gutterBottom variant="h5" component="div">Summary</Typography>
                                <CardContent>
                                    <ul className="list-group list-group-flush">
                                        <li
                                            className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0"
                                        >
                                            Product's Total
                                            <span>${totalAmount()}</span>
                                        </li>
                                        <li
                                            className="list-group-item d-flex justify-content-between align-items-center px-0"
                                        >
                                            Tax
                                            <span>$0</span>
                                        </li>
                                        <li
                                            className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3"
                                        >
                                            <div>
                                                <strong>Total Amount</strong>
                                            </div>
                                            <span><strong>${totalAmount()}</strong></span>
                                        </li>
                                    </ul>
                                </CardContent>
                            </CuCard>
                            {
                                sessionStorage.getItem('token') &&
                                <Card />
                            }
                        </div>
                    </div>
                </section>
            </Grid>
        </>
    )
}
